import {autoserialize, autoserializeAs} from 'cerialize';
import {
    CustomWorkflowOptionModel,
    EWorkflowActionOptionDisplayType,
    EWorkflowActionOptionName,
    EWorkflowActionOptionNotificationType
} from './custom-workflow-option.model';
import {EStickyNoteStatus} from '../../app.enums';
import {ITableItem} from '@relayter/rubber-duck';
import {IQueryParam} from '../../classes/query-params';

export enum EWorkflowConfigurationActionType {
    GENERAL_ACTION =  'GENERAL_ACTION', // for component without any component type, for frontend only
    TRANSITION_TRIGGER =  'TRANSITION_TRIGGER',
    ITEM_ACTION = 'ITEM_ACTION',
    BRIEFING_ACTION = 'BRIEFING_ACTION'
}

export enum EWorkflowActionName {
    // for overview/step overview
    ARRANGE_PAGES = 'ARRANGE_PAGES',
    // other actions, no type, related to preview
    SHOW_BRIEFING_CHANGES = 'SHOW_BRIEFING_CHANGES',
    SHOW_LINKED_BRIEFING_ITEM = 'SHOW_LINKED_BRIEFING_ITEM',
    EDIT_CONTENT_BRIEFING_ITEM = 'EDIT_CONTENT_BRIEFING_ITEM',
    // other actions, no type, related to package
    ADD_TO_PACKAGE = 'ADD_TO_PACKAGE',
    REMOVE_FROM_PACKAGE = 'REMOVE_FROM_PACKAGE',
    ADD_OR_EDIT_PACKAGE = 'ADD_OR_EDIT_PACKAGE',
    // layout component
    EDIT_LAYOUT = 'EDIT_LAYOUT',
    EDIT_LAYOUT_BRIEFING_ITEM = 'EDIT_LAYOUT_BRIEFING_ITEM',
    SHOW_LAYOUT_REVISIONS = 'SHOW_LAYOUT_REVISIONS',
    // TODO: this should be EBriefingActionName, need migration
    INLINE_EDITING = 'INLINE_EDITING'
}

export enum ETransitionTriggerActionName {
    // for transition trigger actions that doesn't require a name
    TRANSITION_TRIGGER = 'TRANSITION_TRIGGER',
    // transition action names
    ADD_PAGES = 'ADD_PAGES',
    UPLOAD_ASSETS = 'UPLOAD_ASSETS',
    UPLOAD_ITEM_FILES = 'UPLOAD_ITEM_FILES',
}

export enum EBriefingActionName {
    ASSIGN = 'ASSIGN', // assign briefing items to publication item
    GENERATE = 'GENERATE', // generate items from campaign item selection (single/multi), requires a transition OMG!!
}

// item here refers to publication item
export enum EItemActionName {
    ASSIGN = 'ASSIGN', // assign briefing items to publication item
    ASSIGN_TO_PACKAGE = 'ASSIGN_TO_PACKAGE',
    DOWNLOAD = 'DOWNLOAD',
    EDIT = 'EDIT', // edit publication item tags & template ...
    SIGN_OFF = 'SIGN_OFF'
}

/**
 * @deprecated
 * we combine all the download actions to one action 'DOWNLOAD' with different package options
 */
export enum EDownloadActionName {
    DOWNLOAD_INDESIGN_PACKAGE = 'DOWNLOAD_INDESIGN_PACKAGE',
    DOWNLOAD_SOURCE_FILES_PACKAGE = 'DOWNLOAD_SOURCE_FILES_PACKAGE',
    DOWNLOAD_LINK_PACKAGE = 'DOWNLOAD_LINK_PACKAGE',
    DOWNLOAD_PDF_PACKAGE = 'DOWNLOAD_PDF_PACKAGE',
    DOWNLOAD_MERGED_PDF = 'DOWNLOAD_MERGED_PDF',
    DOWNLOAD_PNG_PACKAGE = 'DOWNLOAD_PNG_PACKAGE',
    DOWNLOAD_MERGED_PDF_IN_LAYOUT = 'DOWNLOAD_MERGED_PDF_IN_LAYOUT',
    DOWNLOAD_CUSTOM_EXPORT_PACKAGE = 'DOWNLOAD_CUSTOM_EXPORT_PACKAGE'
}

export enum EStickyNoteActionName {
    SHOW_STICKY_NOTES = 'SHOW_STICKY_NOTES',
    CREATE_STICKY_NOTE = 'CREATE_STICKY_NOTE',
    EDIT_STICKY_NOTE_STATUS = 'EDIT_STICKY_NOTE_STATUS',
    EDIT_OWN_STICKY_NOTE_STATUS = 'EDIT_OWN_STICKY_NOTE_STATUS',
    EDIT_STICKY_NOTE_MESSAGE = 'EDIT_STICKY_NOTE_MESSAGE',
    EDIT_OWN_STICKY_NOTE_MESSAGE = 'EDIT_OWN_STICKY_NOTE_MESSAGE',
    EDIT_BRIEFING_ITEM = 'EDIT_BRIEFING_ITEM',
    SHOW_NOTE_BRIEFING_CHANGELOG = 'SHOW_NOTE_BRIEFING_CHANGELOG',
    LINK = 'LINK',
    UNLINK = 'UNLINK',
    ADD_COMMENT = 'ADD_COMMENT',
    SHOW_COMMENTS = 'SHOW_COMMENTS',
}

export class CustomWorkflowActionModel implements ITableItem {
    @autoserialize public _id: string;
    @autoserialize public name: string;
    @autoserialize public from: EStickyNoteStatus[];
    @autoserialize public to: EStickyNoteStatus;
    @autoserialize public title: string;
    @autoserialize public icon: string = 'nucicon_question_fill'; // default icon
    @autoserialize public permissions: string[];
    @autoserialize public transition: string;
    @autoserialize public type: EWorkflowConfigurationActionType;
    @autoserializeAs(CustomWorkflowOptionModel) public options: CustomWorkflowOptionModel[];

    constructor(name?: string, permissions?: string[]) {
        this.name = name;
        this.permissions = permissions;
    }

    get fromStatusFilter(): IQueryParam[] {
        return [{queryParam: 'statuses', value: this.from || []}];
    }
}

export type IActionName = EWorkflowActionName | EBriefingActionName | EStickyNoteActionName | EItemActionName | ETransitionTriggerActionName |
    EDownloadActionName;

export interface IActionOptionConfig {
    name: EWorkflowActionOptionName;
    type?: EWorkflowActionOptionNotificationType | EWorkflowActionOptionDisplayType;
}

export interface IActionConfig {
    name: IActionName;
    title: string;
    fromApplicable?: boolean;
    toApplicable?: boolean;
    optionsConfig?: IActionOptionConfig[];
    sequentialNumberingRequired?: boolean; // needs workflow to have sequentialNumbering === true
}

// TODO: make this more specific
export type IActionOptionValue = any;

export const CUSTOM_WORKFLOW_ACTIONS_CONFIGS: IActionConfig[] =
    [
        {
            name: EWorkflowActionName.ARRANGE_PAGES,
            title: 'Arrange pages',
            sequentialNumberingRequired: true
        },
        {
            name: EWorkflowActionName.SHOW_BRIEFING_CHANGES,
            title: 'Show briefing changes',
            optionsConfig: [
                {
                    // only support dataFields, for example 'MB-title', no 'dataFields.' in the front
                    name: EWorkflowActionOptionName.DISPLAY, type: EWorkflowActionOptionDisplayType.SINGLE
                }
            ]
        },
        {
            name: EWorkflowActionName.SHOW_LINKED_BRIEFING_ITEM,
            title:  'Show linked briefing item',
            optionsConfig: [
                {
                    // only support dataFields, for example 'MB-title', no 'dataFields.' in the front
                    name: EWorkflowActionOptionName.DISPLAY, type: EWorkflowActionOptionDisplayType.MULTI
                }
            ]
        },
        {
            name: EWorkflowActionName.EDIT_CONTENT_BRIEFING_ITEM,
            title:  'Edit briefing item in the content'
        },
        {
            name: EWorkflowActionName.ADD_TO_PACKAGE,
            title:  'Add publication item to a package'
        },
        {
            name: EWorkflowActionName.REMOVE_FROM_PACKAGE,
            title:  'Remove publication item from a package'
        },
        {
            name: EWorkflowActionName.ADD_OR_EDIT_PACKAGE,
            title:  'Add publication item to a package or edit package data'
        },
        {
            name: EWorkflowActionName.EDIT_LAYOUT,
            title:  'Edit layout',
            optionsConfig: [
                {
                    name: EWorkflowActionOptionName.AUTO_ASSIGN
                }
            ]
        },
        {
            name: EWorkflowActionName.EDIT_LAYOUT_BRIEFING_ITEM,
            title:  'Edit briefing item layout '
        },
        {
            name: EWorkflowActionName.SHOW_LAYOUT_REVISIONS,
            title:  'Show layout revisions'
        },
        {
            name: EWorkflowActionName.INLINE_EDITING,
            title:  'Inline editing briefing item'
        },
        {
            name: ETransitionTriggerActionName.TRANSITION_TRIGGER,
            title: 'Transition trigger'
        },
        {
            name: ETransitionTriggerActionName.ADD_PAGES,
            title:  'Add pages',
            optionsConfig: [
                {
                    name: EWorkflowActionOptionName.TEMPLATE_FILTER_CAMPAIGN_TAGS
                },
                {
                    name: EWorkflowActionOptionName.CHANNEL
                },
                {
                    name: EWorkflowActionOptionName.ENGINE_TYPES
                },
                {
                    name: EWorkflowActionOptionName.TEMPLATE_FILTER_TEMPLATE_TAGS
                },
                {
                    name: EWorkflowActionOptionName.TEMPLATE_FILTER_TEMPLATE_TYPES
                }
            ]
        },
        {
            name: ETransitionTriggerActionName.UPLOAD_ASSETS,
            title:  'Update assets'
        },
        {
            name: ETransitionTriggerActionName.UPLOAD_ITEM_FILES,
            title:  'Upload item files'
        },
        {
            name: EBriefingActionName.ASSIGN,
            title: 'Assign briefing item to publication item'
        },
        {
            name: EBriefingActionName.GENERATE,
            title:  'Generate publication item',
            optionsConfig: [
                {
                    name: EWorkflowActionOptionName.ADD_PACKAGE_DATA
                },
                {
                    name: EWorkflowActionOptionName.ADD_NOTES
                },
                {
                    name: EWorkflowActionOptionName.CAMPAIGN_ITEM_FIELDS
                },
                {
                    name: EWorkflowActionOptionName.CHANNEL
                },
                {
                    name: EWorkflowActionOptionName.ENGINE_TYPES
                },
                {
                    name: EWorkflowActionOptionName.TEMPLATE_FILTER_CAMPAIGN_TAGS
                },
                {
                    name: EWorkflowActionOptionName.TEMPLATE_FILTER_TEMPLATE_TAGS
                },
                {
                    name: EWorkflowActionOptionName.TEMPLATE_FILTER_TEMPLATE_TYPES
                }
            ]
        },
        {
            name: EItemActionName.ASSIGN_TO_PACKAGE,
            title:  'Assign publication item to package'
        },
        {
            name: EItemActionName.DOWNLOAD,
            title:  'Download',
            optionsConfig: [
                {
                    name: EWorkflowActionOptionName.PACKAGES
                }
            ]
        },
        {
            name: EItemActionName.EDIT,
            title:  'Edit',
            optionsConfig: [
                {
                    name: EWorkflowActionOptionName.CHANNEL
                },
                {
                    name: EWorkflowActionOptionName.ENGINE_TYPES
                },
                {
                    name: EWorkflowActionOptionName.TEMPLATE_FILTER_CAMPAIGN_TAGS
                },
                {
                    name: EWorkflowActionOptionName.TEMPLATE_FILTER_TEMPLATE_TAGS
                },
                {
                    name: EWorkflowActionOptionName.TEMPLATE_FILTER_TEMPLATE_TYPES
                }
            ]
        },
        {
            name: EItemActionName.SIGN_OFF,
            title:  'Approve items'
        },
        {
            name: EDownloadActionName.DOWNLOAD_INDESIGN_PACKAGE,
            title:  'Download InDesign package'
        },
        {
            name: EDownloadActionName.DOWNLOAD_SOURCE_FILES_PACKAGE,
            title:  'Download source files package'
        },
        {
            name: EDownloadActionName.DOWNLOAD_LINK_PACKAGE,
            title:  'Download link package'
        },
        {
            name: EDownloadActionName.DOWNLOAD_PDF_PACKAGE,
            title:  'Download PDF package'
        },
        {
            name: EDownloadActionName.DOWNLOAD_MERGED_PDF,
            title:  'Download merged PDF'
        },
        {
            name: EDownloadActionName.DOWNLOAD_PNG_PACKAGE,
            title:  'Download png package'
        },
        {
            name: EDownloadActionName.DOWNLOAD_MERGED_PDF_IN_LAYOUT,
            title:  'Download merged PDF in layout'
        },
        {
            name: EDownloadActionName.DOWNLOAD_CUSTOM_EXPORT_PACKAGE,
            title:  'Download custom export package'
        },
        {
            name: EStickyNoteActionName.SHOW_STICKY_NOTES,
            title:  'Show sticky notes',
            fromApplicable: true,
            optionsConfig: [
                {
                    // only support dataFields, for example 'MB-title', no 'dataFields.' in the front
                    name: EWorkflowActionOptionName.DISPLAY, type: EWorkflowActionOptionDisplayType.MULTI
                }
            ]
        },
        {
            name: EStickyNoteActionName.CREATE_STICKY_NOTE,
            title:  'Create sticky notes',
            optionsConfig: [
                {
                    name: EWorkflowActionOptionName.ADD_FOR_RELATED_PUBLICATION_ITEMS
                },
                {
                    name: EWorkflowActionOptionName.REMOVE_SIGN_OFFS
                }
            ]
        },
        {
            name: EStickyNoteActionName.EDIT_STICKY_NOTE_STATUS,
            title:  'Edit note status',
            fromApplicable: true,
            toApplicable: true,
            optionsConfig: [
                {
                    name: EWorkflowActionOptionName.NOTIFICATION, type: EWorkflowActionOptionNotificationType.NOTE_UPDATE
                }
            ]
        },
        {
            name: EStickyNoteActionName.EDIT_OWN_STICKY_NOTE_STATUS,
            title:  'Edit own sticky note status',
            fromApplicable: true,
            toApplicable: true,
        },
        {
            name: EStickyNoteActionName.EDIT_STICKY_NOTE_MESSAGE,
            title:  'Edit note message',
            fromApplicable: true,
        },
        {
            name: EStickyNoteActionName.EDIT_OWN_STICKY_NOTE_MESSAGE,
            title:  'Edit own note message',
            fromApplicable: true,
        },
        {
            name: EStickyNoteActionName.EDIT_BRIEFING_ITEM,
            title:  'Edit briefing item',
            fromApplicable: true
        },
        {
            name: EStickyNoteActionName.SHOW_NOTE_BRIEFING_CHANGELOG,
            title:  'Show note briefing changelog',
            fromApplicable: true
        },
        {
            name: EStickyNoteActionName.LINK,
            title:  'Link briefing item to a note',
            fromApplicable: true
        },
        {
            name: EStickyNoteActionName.UNLINK,
            title:  'Unlink briefing item from a note',
            fromApplicable: true
        },
        {
            name: EStickyNoteActionName.ADD_COMMENT,
            title:  'Add comment to note',
            fromApplicable: true,
            optionsConfig: [
                {
                    name: EWorkflowActionOptionName.NOTIFICATION, type: EWorkflowActionOptionNotificationType.NOTE_COMMENT
                }
            ]
        },
        {
            name: EStickyNoteActionName.SHOW_COMMENTS,
            title:  'Show comments',
            fromApplicable: true
        }
    ];
