import {Component, DestroyRef, inject, Inject, OnInit} from '@angular/core';
import {AbstractControl, FormControl, FormGroup, ValidatorFn} from '@angular/forms';
import {ARApiError} from '@relayter/core';
import {Toaster} from '../../classes/toaster.class';
import {RLValidatorConstants} from '../../classes/validators/rl-validators.constant';
import {PublicationModel, PublicationPatchModel, PublicationPostModel} from '../../models/api/publication.model';
import {AppConstants} from '../../app.constants';
import {distinctUntilChanged, map} from 'rxjs/operators';
import {
    BUTTON_TYPE,
    ButtonConfig,
    FullModalActionModel,
    FullModalService,
    NUC_FULL_MODAL_DATA,
    NucDialogConfigModel,
    NucDialogService
} from '@relayter/rubber-duck';
import {RLSegmentService} from '../../services/segment/rl-segment.service';
import {PublicationsService} from '../../api/services/publications.service';
import {WorkflowConfigurationModel} from '../../models/api/workflow-configuration.model';
import {DropdownItem} from '../../models/ui/dropdown-item.model';
import {ISegmentService, SEGMENT_SERVICE} from '../../services/segment/segment.service.interface';
import {VariantService} from '../../api/services/variant.service';
import {VariantModel} from '../../models/api/variant.model';
import {WorkflowConfigurationsService} from '../../api/services/workflow-configurations.service';
import {EFormStatus} from '../../app.enums';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {forkJoin} from 'rxjs';

export interface IPublicationFormComponentData {
    campaignId: string;
    publication?: PublicationModel;
}

export interface IPublicationForm {
    name: FormControl<string>;
    tags: FormControl<DropdownItem<string>[]>;
    variants: FormControl<VariantModel[]>;
    workflow: FormControl<WorkflowConfigurationModel>;
    deadline: FormControl<Date>;
}

@Component({
    selector: 'publication-form-component',
    templateUrl: 'publication-form.component.html',
    styleUrls: ['publication-form.component.scss']
})
export class PublicationFormComponent implements OnInit {
    private destroyRef = inject(DestroyRef);
    public formGroup: FormGroup<IPublicationForm>;
    public pageSize = AppConstants.PAGE_SIZE_DEFAULT;
    public workflowConfigurations: WorkflowConfigurationModel[] = [];

    private readonly campaignId: string;
    public readonly publicationToEdit: PublicationModel;

    private saveButton: ButtonConfig;
    private cancelButton: ButtonConfig;

    public tagOptions: DropdownItem<string>[];
    public variants: VariantModel[];

    constructor(@Inject(NUC_FULL_MODAL_DATA) private modalData: IPublicationFormComponentData,
                private fullModalService: FullModalService,
                private publicationsService: PublicationsService,
                private workflowConfigurationService: WorkflowConfigurationsService,
                private variantService: VariantService,
                private dialogService: NucDialogService,
                @Inject(SEGMENT_SERVICE) private segmentService: ISegmentService) {
        this.publicationToEdit = modalData.publication;
        this.campaignId = modalData.campaignId;
    }

    public ngOnInit(): void {

        forkJoin({
            variants: this.variantService.getVariants(this.campaignId).pipe(map((result) => result.items)),
            workflowConfigurations: this.workflowConfigurationService.findAll()
        }).pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: ({variants, workflowConfigurations}) => {
                    this.variants = variants;
                    this.workflowConfigurations = workflowConfigurations;
                    this.initForm();
                    this.initModalButtons();
                    this.listenToFormStatus();
                },
                error: Toaster.handleApiError
            });
    }

    private initModalButtons(): void {
        // set up the buttons for full modal
        this.saveButton = new ButtonConfig(BUTTON_TYPE.PRIMARY, this.publicationToEdit ? 'Save' : 'Create', null, null, !this.publicationToEdit);
        this.cancelButton = new ButtonConfig(BUTTON_TYPE.SECONDARY, 'Cancel');
        const cancel = new FullModalActionModel(this.cancelButton);
        cancel.observable.subscribe(() => this.fullModalService.close(false, true));
        const save = new FullModalActionModel(this.saveButton);
        save.observable.subscribe(() => {
            this.publicationToEdit ? this.onEditClicked() : this.onCreateClicked();
        });
        this.fullModalService.setModalActions([cancel, save]);
    }

    private validateDeadline(): ValidatorFn {
        return (control: AbstractControl) => {
            return !control.pristine && control.value && control.value < new Date() ? {invalidDate: {value: control.value}} : null;
        };
    }

    private initForm(): void {
        const selectedVariants = this.variants.filter((variant) =>
            this.publicationToEdit?.variants.some((selectedVariantId) => selectedVariantId === variant._id));

        this.formGroup = new FormGroup<IPublicationForm>({
            name: new FormControl(this.publicationToEdit?.name, RLValidatorConstants.VALIDATOR_SETS.REQUIRED),
            tags: new FormControl(this.publicationToEdit?.tags ?
                this.publicationToEdit.tags.map((tag: string) => new DropdownItem(tag, tag)) :
                []),
            variants: new FormControl({value: selectedVariants, disabled: !!this.publicationToEdit}),
            workflow: new FormControl({
                value: this.publicationToEdit?.workflow,
                disabled: !!this.publicationToEdit
            }, RLValidatorConstants.VALIDATOR_SETS.REQUIRED),
            deadline: new FormControl(this.publicationToEdit?.deadline, [this.validateDeadline()])
        });
    }

    private listenToFormStatus(): void {
        this.formGroup.statusChanges.pipe(
            distinctUntilChanged(),
            takeUntilDestroyed(this.destroyRef)
        ).subscribe((status) => {
            this.saveButton.disabled = status !== EFormStatus.VALID; // disable the button based on the form status
        });
    }

    public onTagChanged(event: string): void {
        this.tagOptions = event && event.length && event.trim().length ? [new DropdownItem<string>(event.trim(), event.trim())] : [];
    }

    /**
     * when clicking the Create button
     * show confirmation dialog when campaign has variants configured but no variants selected for publication
     */
    private onCreateClicked(): void {
        const tags = this.formGroup.value.tags.map((tag: DropdownItem<string>) => tag.getValue());
        const selectedVariants = this.formGroup.value.variants.map((selectedVariant) => selectedVariant._id);
        const publicationBody = new PublicationPostModel(
            this.formGroup.value.name.trim(),
            this.formGroup.value.workflow._id,
            tags,
            selectedVariants,
            this.formGroup.value.deadline);

        if (this.variants?.length > 0 && selectedVariants.length === 0) {
            const dialog = new NucDialogConfigModel('Create publication',
                'This publication currently has no selected variants, while the campaign has variants configured. ' +
                'Are you sure you want to continue? Variants cannot be added to a publication at a later time.');
            const confirmDialog = this.dialogService.openDialog(dialog);
            dialog.addAction('Cancel', BUTTON_TYPE.SECONDARY).subscribe(() => confirmDialog.close());
            dialog.addAction('Save', BUTTON_TYPE.PRIMARY).subscribe(() => {
                confirmDialog.close();
                this.saveButton.loading = true;
                this.postPublication(publicationBody);
            });
        } else {
            this.postPublication(publicationBody);
        }
    }

    private postPublication(publicationBody: PublicationPostModel): void {
        this.publicationsService.postPublicationsForCampaign(this.campaignId, publicationBody).subscribe({
            next: (res: PublicationModel) => {
                this.saveButton.loading = false;
                this.segmentService.track(RLSegmentService.TRACK_ADD_PUBLICATION(res.name));
                Toaster.success('Publication added successfully');
                this.fullModalService.close(true);
            },
            error: (err: ARApiError) => this.handleSaveError(err)
        });
    }

    /**
     * when edit a publication
     */
    private onEditClicked(): void {
        this.saveButton.loading = true;
        const tags = this.formGroup.value.tags.map((tag: DropdownItem<string>) => tag.getValue());
        const deadline = this.formGroup.value.deadline;
        const publication = new PublicationPatchModel(this.formGroup.value.name.trim(), tags, deadline);

        this.publicationsService.updatePublicationsForCampaign(this.campaignId, this.publicationToEdit._id, publication)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: () => {
                    this.saveButton.loading = false;
                    Toaster.success('Publication edited successfully');
                    this.fullModalService.close(true);
                },
                error: (err: ARApiError) => this.handleSaveError(err)
            });

    }

    private handleSaveError(err: ARApiError): void {
        this.saveButton.loading = false;
        Toaster.handleApiError(err);
    }
}
