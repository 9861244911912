import {Injectable, OnDestroy} from '@angular/core';
import {BehaviorSubject, ReplaySubject, Subject} from 'rxjs';
import {StaticContentTemplateSizeModel} from './static-content-template-size.model';
import {EChannel} from '../../../../../app.enums';
import {TemplateAreaModel} from '../../../../../models/api/template-area.model';

@Injectable()
export class StaticContentTemplateEditorDataService implements OnDestroy {
    public backgroundUrlSubject = new BehaviorSubject<string>(null);

    public contentsSubject = new ReplaySubject<TemplateAreaModel[]>(1);

    public selectedContentIndexSubject = new BehaviorSubject<number>(-1);

    public hoveredContentIndexSubject = new Subject<number>();

    public templateSizeSubject = new Subject<StaticContentTemplateSizeModel>();

    public channelSubject = new Subject<EChannel>();

    public collisionsSubject = new Subject<Set<TemplateAreaModel>>();

    public numberOfPagesSubject = new Subject<number>();

    public ngOnDestroy(): void {
        [
            this.backgroundUrlSubject,
            this.contentsSubject,
            this.selectedContentIndexSubject,
            this.hoveredContentIndexSubject,
            this.templateSizeSubject,
            this.numberOfPagesSubject
        ].forEach((subject) => {
            subject.complete();
        });
    }

    public setChannel(channel: EChannel): void {
        this.channelSubject.next(channel);
    }

    public setBackgroundUrl(signedUrl: string): void {
        this.backgroundUrlSubject.next(signedUrl);
    }

    public setContents(contents: TemplateAreaModel[]): void {
        this.contentsSubject.next(contents);
    }

    public setSelectedContentIndex(index: number): void {
        this.selectedContentIndexSubject.next(index);
    }

    public setHoveredContentIndex(index: number): void {
        this.hoveredContentIndexSubject.next(index);
    }

    public setTemplateSize(templateSize: StaticContentTemplateSizeModel): void {
        this.templateSizeSubject.next(templateSize);
    }

    // this is used to check overlapping areas
    public setCollisions(collisions: Set<TemplateAreaModel>): void {
        this.collisionsSubject.next(collisions);
    }

    public setNumberOfPages(numberOfPages: number): void {
        this.numberOfPagesSubject.next(numberOfPages);
    }
}
