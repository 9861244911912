import {Component, OnInit} from '@angular/core';
import {CustomWorkflowActionModel} from '../../../../../../models/api/custom-workflow-action.model';
import {UserIsAllowedToPipe} from '../../../../../../pipes/user-is-allowed-to.pipe';
import {FullModalConfig, FullModalService, NucDialogService} from '@relayter/rubber-duck';
import {AddPageFormComponent, IAddPageActionOptions, IAddPageFormData} from '../../../../../../forms/add-page-form/add-page-form.component';
import {CustomWorkflowService} from '../custom-workflow.service';
import {CustomWorkflowBaseComponent} from '../custom-workflow-base.component';
import {PublicationsService} from '../../../../../../api/services/publications.service';
import {CustomWorkflowOptionModel, EWorkflowActionOptionName} from '../../../../../../models/api/custom-workflow-option.model';
import {MonitoredTransitionsService} from '../../../../../../api/services/monitored-transitions.service';
import {EEngineType, EPageType} from '../../../../../../models/api/template.model';

enum EComponentActions {
    ADD_PAGES = 'ADD_PAGES',
}

interface IPageType {
    title: string;
    type: EPageType;
    engineTypes: EEngineType[];
    page?: number;
}

@Component({
    selector: 'rl-custom-workflow-add-pages-component',
    templateUrl: './custom-workflow-add-pages.component.html',
    styleUrls: ['./custom-workflow-add-pages.component.scss']
})
export class CustomWorkflowAddPagesComponent extends CustomWorkflowBaseComponent implements OnInit {
    private static SINGLE_PAGE_TEMPLATE = 'Single page template';
    private static SPREAD_TEMPLATE = 'Spread template';
    private static ANIMATED_CONTENT_TEMPLATE = 'Animated Content template';

    public addPagesAction: CustomWorkflowActionModel;
    public pageTypes: IPageType[] = []
    private actionOptionEngineTypes: CustomWorkflowOptionModel;

    private filterOptions(pageType: IPageType): IAddPageActionOptions {
        return {
            engineTypes: pageType?.engineTypes,
            channel: this.addPagesAction?.options?.find((option) => option.name === EWorkflowActionOptionName.CHANNEL)?.value || null,
            templateFilterCampaignTags: this.addPagesAction?.options?.find((option) =>
                option.name === EWorkflowActionOptionName.TEMPLATE_FILTER_CAMPAIGN_TAGS)?.value === true ? this.campaign.tags : [],
            templateFilterTemplateTags: this.addPagesAction?.options?.find((option) =>
                option.name === EWorkflowActionOptionName.TEMPLATE_FILTER_TEMPLATE_TAGS)?.value || [],
            templateFilterTemplateTypes: this.addPagesAction?.options?.find((option) =>
                option.name === EWorkflowActionOptionName.TEMPLATE_FILTER_TEMPLATE_TYPES)?.value || null
        } as IAddPageActionOptions;
    }

    constructor(userIsAllowedToPipe: UserIsAllowedToPipe,
                customWorkflowService: CustomWorkflowService,
                publicationService: PublicationsService,
                dialogService: NucDialogService,
                fullModalService: FullModalService,
                monitoredTransitionsService: MonitoredTransitionsService) {
        super(userIsAllowedToPipe, customWorkflowService, publicationService, dialogService, fullModalService, monitoredTransitionsService);
    }

    public ngOnInit(): void {
        super.ngOnInit();

    }

    protected updateActions(): void {
        super.updateActions();
        this.addPagesAction = this.findWorkflowActionByName(EComponentActions.ADD_PAGES);
        this.actionOptionEngineTypes = this.addPagesAction?.options?.find(option => option.name === EWorkflowActionOptionName.ENGINE_TYPES);

        this.updatePageTypes();
    }

    private updatePageTypes() {
        const engineTypes = (this.actionOptionEngineTypes?.value || [EEngineType.INDESIGN]) as EEngineType[];
        this.pageTypes = [];
        let pageType: IPageType;

        for (const engineType of engineTypes) {
            switch (engineType) {
                case EEngineType.INDESIGN:
                case EEngineType.SVG: {
                    pageType = this.pageTypes.find(type => type.title === CustomWorkflowAddPagesComponent.SINGLE_PAGE_TEMPLATE);
                    if (!pageType) {
                        pageType = {title: CustomWorkflowAddPagesComponent.SINGLE_PAGE_TEMPLATE, type: EPageType.Single, page: 1, engineTypes: []};
                        this.pageTypes.push(pageType)
                    }
                    pageType.engineTypes.push(engineType);

                    pageType = this.pageTypes.find(type => type.title === CustomWorkflowAddPagesComponent.SPREAD_TEMPLATE);
                    if (!pageType) {
                        pageType = {title: CustomWorkflowAddPagesComponent.SPREAD_TEMPLATE, type: EPageType.Spread, page: 2, engineTypes: []};
                        this.pageTypes.push(pageType)
                    }
                    pageType.engineTypes.push(engineType);
                    break;
                }
                case EEngineType.AFTER_EFFECTS:
                    pageType = this.pageTypes.find(type => type.title === CustomWorkflowAddPagesComponent.ANIMATED_CONTENT_TEMPLATE);
                    if (!pageType) {
                        pageType = {title: CustomWorkflowAddPagesComponent.ANIMATED_CONTENT_TEMPLATE, type: EPageType.Single, engineTypes: []};
                        this.pageTypes.push(pageType)
                    }
                    pageType.engineTypes.push(engineType);
                    break;
            }
        }
    }

    protected setupData(): void {}

    protected refreshData(): void {}

    public openAddPageModal(selectedPageType: IPageType): void {
        const modalData: IAddPageFormData = {
            selectedPageType: selectedPageType.page,
            publicationId: this.publication._id,
            transition: this.addPagesAction.transition,
            filterOptions: this.filterOptions(selectedPageType)
        };
        const modalConfig = new FullModalConfig('Add page(s)', 'Choose the template and the amount of pages.', modalData);
        this.fullModalService.open(AddPageFormComponent, modalConfig);
    }
}
