import {Component, Inject, OnInit} from '@angular/core';
import {FullModalService, NUC_FULL_MODAL_DATA} from '@relayter/rubber-duck';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AssetFormatOption} from '../static-content-ruleset.constants';
import {StaticContentRulesetService} from '../../../api/services/static-content-ruleset.service';
import {RulePropertyModel} from '../../../models/api/rule-property.model';
import {
    BaseRulesetItemFormComponent,
    ERulesetContext,
    IFormatRulesetComponentData
} from '../../../forms/rule-form/base-ruleset-item-form.component';
import {Toaster} from '../../../classes/toaster.class';
import {
    AssetFormatModel,
    StaticContentRulesetAssetItemModel
} from '../models/api/static-content-ruleset-asset-item.model';
import {DataCollectionService} from '../../../api/services/data-collection.service';
import {DropdownItem} from '../../../models/ui/dropdown-item.model';

export interface IFormatRulesetAssetItemFormComponentData extends IFormatRulesetComponentData {
    ruleSetId: string;
    item: StaticContentRulesetAssetItemModel;
    ruleProperties: RulePropertyModel[];
}

@Component({
    selector: 'static-content-ruleset-asset-item-form',
    templateUrl: './static-content-ruleset-asset-item-form.component.html',
    styleUrls: ['./static-content-ruleset-asset-item-form.component.scss']
})
export class StaticContentRulesetAssetItemFormComponent extends BaseRulesetItemFormComponent implements OnInit {
    public layerControl = new FormControl(null, Validators.required);
    public xControl = new FormControl('0', Validators.required);
    public yControl = new FormControl('0', Validators.required);
    public wControl = new FormControl('0', [Validators.required, Validators.min(0)]);
    public hControl = new FormControl('0', [Validators.required, Validators.min(0)]);
    public horizontalMarginControl = new FormControl('0', Validators.required);
    public verticalMarginControl = new FormControl('0', Validators.required);
    public formatControl = new FormControl(AssetFormatOption.GRID, Validators.required);
    public formatOptions = AssetFormatOption.OPTIONS;
    public limitControl = new FormControl(0, [Validators.required, Validators.min(0)]);
    public skipControl = new FormControl(0, [Validators.required, Validators.min(0)]);

    public modalData: IFormatRulesetAssetItemFormComponentData;

    constructor(fullModalService: FullModalService,
                private ruleSetService: StaticContentRulesetService,
                protected dataCollectionService: DataCollectionService,
                @Inject(NUC_FULL_MODAL_DATA) modalData: IFormatRulesetAssetItemFormComponentData) {
        super(fullModalService, dataCollectionService);
        this.modalData = modalData;
        this.formGroup = new FormGroup({
            name: this.nameControl,
            layer: this.layerControl,
            x: this.xControl,
            y: this.yControl,
            w: this.wControl,
            h: this.hControl,
            format: this.formatControl,
            limit: this.limitControl,
            skip: this.skipControl,
            horizontalMargin: this.horizontalMarginControl,
            verticalMargin: this.verticalMarginControl,
            conditions: this.conditionGroups
        });
    }

    public ngOnInit(): void {
        this.layerProperty = this.modalData.context === ERulesetContext.ITEMS ? 'items.layer' : 'assetItems.layer';
        this.getLayers();

        super.ngOnInit();
    }

    protected initForm(): void {
        const modalData = this.modalData as IFormatRulesetAssetItemFormComponentData;
        if (modalData.item) {
            const patch = {
                ...modalData.item,
                name: modalData.item.name,
                x: modalData.item.x,
                y: modalData.item.y,
                w: modalData.item.width,
                h: modalData.item.height,
                layer: [new DropdownItem(modalData.item.layer, modalData.item.layer)],
                format: AssetFormatOption.getByValue(modalData.item.format.displayFormat),
                horizontalMargin: modalData.item.format.horizontalMargin,
                verticalMargin: modalData.item.format.verticalMargin,
                limit: modalData.item.limit ?  modalData.item.limit : 0,
                skip: modalData.item.skip ?  modalData.item.skip : 0,
            };

            this.formGroup.patchValue(patch);
        }
        super.initForm();
    }

    protected saveRule(): void {
        const conditions = this.getConditions();

        const ruleSetAssetItem = new StaticContentRulesetAssetItemModel(
            this.formGroup.value.name,
            this.formGroup.value.layer[0].getValue(),
            this.formGroup.value.x,
            this.formGroup.value.y,
            this.formGroup.value.w,
            this.formGroup.value.h,
            new AssetFormatModel(
                this.formGroup.value.format.getValue(),
                this.formGroup.value.horizontalMargin.toString(),
                this.formGroup.value.verticalMargin.toString()
            ),
            this.formGroup.value.limit,
            this.formGroup.value.skip,
            conditions);

        const modalData = this.modalData as IFormatRulesetAssetItemFormComponentData;

        const observable = this.modalData.item
            ? this.ruleSetService.updateStaticContentRulesetAssetItem(modalData.ruleSetId, modalData.item._id, ruleSetAssetItem)
            : this.ruleSetService.postStaticContentRulesetAssetItem(modalData.ruleSetId, ruleSetAssetItem);

        observable.subscribe((result) => {
            Toaster.success(`Successfully ${this.modalData.item ? 'updated' : 'created'} rule: ${ruleSetAssetItem.name}`);
            this.fullModalService.close(result);
        }, Toaster.handleApiError);
    }
}
