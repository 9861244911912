import {Component, Inject, OnInit} from '@angular/core';
import {AppConstants} from '../../app.constants';
import {UserIsAllowedToPipe} from '../../pipes/user-is-allowed-to.pipe';
import {ActivatedRoute, Router} from '@angular/router';
import {ARLogger, ARPagedResponseDataModel} from '@relayter/core';
import {PublicationModel} from '../../models/api/publication.model';
import {Toaster} from '../../classes/toaster.class';
import {
    EPublicationJobType,
    ICopyPublicationJobData,
    IDeletePublicationJobData,
    PublicationsService
} from '../../api/services/publications.service';
import {Subscription} from 'rxjs';
import {
    IPublicationFormComponentData,
    PublicationFormComponent
} from '../../forms/publication-form/publication-form.component';
import {
    BUTTON_TYPE,
    DialogButtonConfig,
    FullModalConfig,
    FullModalService,
    NucDialogConfigModel,
    NucDialogService,
    NucDialogTextConfirmConfig,
    NucDialogTextConfirmData,
    NucDialogTextConfirmService
} from '@relayter/rubber-duck';
import {EJobStatus, JobModel} from '../../models/api/job.model';
import {RLSegmentService} from '../../services/segment/rl-segment.service';
import {CampaignDetailService} from '../../pages/relayter/campaigns/detail/campaign-detail.service';
import {filter} from 'rxjs/operators';
import {SEGMENT_SERVICE} from '../../services/segment/segment.service.interface';
import {MonitoredJobsService} from '../../api/services/monitored-jobs.service';
import {RLTableComponent} from '../rl-base-component/rl-table.component';
import {UserSettingsStorageService} from '../../api/services/user-settings-storage.service';
import {PaginatorService} from '../paginator/paginator.service';
import {MatrixUrlParams} from '../../models/ui/matrix-url-params.model';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';

@Component({
    selector: 'rl-campaigns-publications-component',
    templateUrl: 'campaign-publications-overview.component.html',
    styleUrls: ['campaign-publications-overview.component.scss'],
    providers: [PaginatorService]
})

export class CampaignPublicationsOverviewComponent extends RLTableComponent implements OnInit {
    public readonly tableId = 'campaign-publications-overview-table';
    private campaignId: string;

    public disableNextPage = true;
    public pageIndex: number;
    public pageSize: number;

    public publications: PublicationModel[];

    public publicationsSubscription: Subscription;

    public constructor(private router: Router,
                       private route: ActivatedRoute,
                       private publicationsService: PublicationsService,
                       @Inject(SEGMENT_SERVICE) private segmentService: RLSegmentService,
                       private monitoredJobService: MonitoredJobsService,
                       private fullModalService: FullModalService,
                       private campaignDetailService: CampaignDetailService,
                       private dialogService: NucDialogService,
                       private dialogTextConfirmService: NucDialogTextConfirmService,
                       private userIsAllowedToPipe: UserIsAllowedToPipe,
                       private paginatorService: PaginatorService,
                       userSettingsStorageService: UserSettingsStorageService) {
        super(userSettingsStorageService);
    }

    public ngOnInit(): void {
        this.initFromRoute();

        this.campaignDetailService.publicationAdded$
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(() => this.getPublications());

        this.paginatorService.getPagination(this.tableId)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(pagination => {
                this.pageIndex = pagination.pageIndex;
                this.pageSize = pagination.pageSize;

                this.updateUrl();

                this.getPublications();
            });
    }

    private initFromRoute(): void {
        this.campaignId = this.route.parent.snapshot.params['campaign_id'];

        const params = this.route.snapshot.params;
        const pageIndex = params['pageIndex'] ? parseInt(params['pageIndex'], 10) : AppConstants.PAGE_INDEX_DEFAULT;

        this.paginatorService.setPageIndex(this.tableId, pageIndex);

    }

    private updateUrl(): void {
        this.router.navigate([this.createMatrixUrl()], {replaceUrl: true, relativeTo: this.route});
    }

    /**
     * Create a MatrixUrlParams so the url always has the correct amount of parameters
     * @return {MatrixUrlParams}
     */
    private createMatrixUrl(): MatrixUrlParams {
        return new MatrixUrlParams(this.pageIndex);
    }

    /**
     * Get paged publications from the api
     */
    private getPublications(): void {
        if (this.userIsAllowedToPipe.transform(AppConstants.PERMISSIONS.GET_CAMPAIGN_PUBLICATIONS)) {
            if (this.publicationsSubscription) {
                this.publicationsSubscription.unsubscribe();
            }

            this.publicationsSubscription = this.publicationsService.getPublications(this.campaignId,
                this.pageSize, (this.pageIndex - 1) * this.pageSize, 'asc', 'name')
                .pipe(takeUntilDestroyed(this.destroyRef))
                .subscribe({
                    next: (res: ARPagedResponseDataModel<PublicationModel>) => {
                        this.publications = res.items;
                        this.disableNextPage = res.total <= this.pageIndex * this.pageSize;
                    },
                    error: Toaster.handleApiError
                });
        }
    }

    /**
     * On campaign publication clicked responder
     */
    public campaignPublicationClicked(publication: PublicationModel): void {
        if (this.userIsAllowedToPipe.transform(AppConstants.PERMISSIONS.GET_CAMPAIGN_PUBLICATIONS_DETAILS)) {
            this.router.navigate([AppConstants.CONTEXT_URL.CAMPAIGNS, this.campaignId, AppConstants.CONTEXT_URL.PUBLICATIONS, publication._id]);
        }
    }

    public refreshPage(): void {
        this.pageIndex = 1;
        this.getPublications();
    }

    public openCopyPublicationDialog(publication: PublicationModel): void {
        const copyDialogConfig = new NucDialogConfigModel(`Copy publication ${publication.name}`,
            'Are you sure you want to copy the publication? This will also duplicate all items in the publication.');
        const copyDialog = this.dialogService.openDialog(copyDialogConfig);
        copyDialogConfig.addAction('Cancel', BUTTON_TYPE.SECONDARY).subscribe(() => copyDialog.close());
        copyDialogConfig.addAction('Copy', BUTTON_TYPE.PRIMARY).subscribe(() => {
            copyDialog.close();
            this.duplicatePublication(publication);
        });
    }

    /**
     * Post a job to duplicate a publication
     */
    public duplicatePublication(publication: PublicationModel): void {
        this.publicationsService.postJob(
            EPublicationJobType.COPY_PUBLICATION,
            {publicationId: publication._id} as ICopyPublicationJobData
        )
            .subscribe({
                next: (job: JobModel) => {
                    ARLogger.debug('Job scheduled: ' + job._id);
                    this.monitoredJobService.getJobMonitor(job._id)
                        .subscribe((jobModel: JobModel) => {
                            if (jobModel.status === EJobStatus.DONE) {
                                this.getPublications();
                            }
                        });
                },
                error: Toaster.handleApiError
            });
    }

    public openDeletePublicationDialog(publication: PublicationModel): void {
        const dialogData = new NucDialogTextConfirmData (
            'Type the name of the publication to confirm',
            'Publication name',
            publication.name,
            new DialogButtonConfig(BUTTON_TYPE.DESTRUCTIVE, 'Delete')
        );
        const dialogConfig = new NucDialogTextConfirmConfig('Delete publication',
            dialogData, `Please confirm that you wish to delete ${publication.name}.
            Deleting the publication will remove all related content including InDesign generated or uploaded files.`);

        const dialog = this.dialogTextConfirmService.open(dialogConfig);
        dialog.afterClosed().subscribe((deletionConfirmed: boolean) => {
            if (deletionConfirmed) {
                this.deletePublication(publication);
            }
        });
    }

    /**
     * Deletes publication. Reloads page when deletion is completed
     */
    public deletePublication(publication: PublicationModel): void {
        const jobData = {
            campaignId: this.campaignId,
            publicationId: publication._id,
            name: publication.name
        } as IDeletePublicationJobData;

        this.publicationsService.postJob(EPublicationJobType.PUBLICATION_DELETE_JOB, jobData).subscribe({
            next: (scheduleJob: JobModel) => {
                ARLogger.debug('Job scheduled: ' + scheduleJob._id);
                this.monitoredJobService.getJobMonitor(scheduleJob._id)
                    .subscribe((jobModel: JobModel) => {
                        if (jobModel.status === EJobStatus.DONE) {
                            this.segmentService.track(RLSegmentService.TRACK_REMOVE_PUBLICATION(publication.name));
                            this.refreshPage();
                        }
                    });
            },
            error: Toaster.handleApiError
        });
    }
    public openEditModal(publicationId: string): void {
        this.publicationsService.getPublicationDetails(this.campaignId, publicationId)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: (publication) => {
                    const modalData: IPublicationFormComponentData = {campaignId: this.campaignId, publication};
                    const modalConfig = new FullModalConfig('Edit publication', 'Edit your publication details.', modalData);
                    modalConfig.confirmClose = true;

                    const modal = this.fullModalService.open(PublicationFormComponent, modalConfig);
                    modal.afterClosed().pipe(filter((refresh: boolean) => refresh)).subscribe(() => this.getPublications());
                },
                error: Toaster.handleApiError
            });
    }
}
