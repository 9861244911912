import {Injectable} from '@angular/core';
import {ApiConstants} from '../api.constant';
import {Observable} from 'rxjs';
import {ApiService} from './api.service';
import {TemplateDetailModel, TemplateModel} from '../../models/api/template.model';
import {JobModel} from '../../models/api/job.model';
import {ApiPostJobRequest} from '../requests/api-post-job.request';
import {tap} from 'rxjs/operators';
import {MonitoredJobsService} from './monitored-jobs.service';
import {SortDirection} from '@angular/material/sort';
import {ApiPagedRequest} from '../requests/api-paged.request';
import {ARPagedResponseDataModel} from '@relayter/core';
import {ApiDetailsRequest} from '../requests/api-details.request';
import {TemplateUsageModel} from '../../models/api/template-usage.model';
import {ApiGetRequest} from '../requests/api-get.request';

interface ITemplateJobData {
    templateId: string;
    templateIdToMigrate: string;
}

export enum ETemplateJobType {
    DELETE_TEMPLATE_JOB = 'DELETE_TEMPLATE_JOB'
}

@Injectable({providedIn: 'root'})
export class TemplatesApiService extends ApiService<TemplateModel> {

    constructor(private monitoredJobsService: MonitoredJobsService) {
        super([ApiConstants.API_GROUP_TEMPLATES], TemplateModel);
    }

    public postJob(jobType: ETemplateJobType, jobData: ITemplateJobData): Observable<JobModel> {
        const request = new ApiPostJobRequest(this.groups, JobModel, jobType, jobData);

        return this.apiRequestService.postJob(request)
            .pipe(
                tap((job: JobModel) => this.monitoredJobsService.addJobToMonitor(job))
            );
    }

    public getTemplates(limit?: number, offset?: number,
                        sortProperty?: string, sortOrder?: SortDirection, search?: string,
                        filterValues?: Record<string, any>): Observable<ARPagedResponseDataModel<TemplateModel>> {
        const request = new ApiPagedRequest(
            this.groups,
            TemplateModel,
            limit,
            offset,
            null,
            null,
            search,
            filterValues
        );

        request.additionalQueryParams.setSortAndSortDirectionParams(sortProperty, sortOrder);
        return this.apiRequestService.find(request);
    }

    /**
     * Get the details of a template
     * @param itemId
     * @returns {Observable<TemplateModel>}
     */
    public getTemplateDetails(itemId: string): Observable<TemplateDetailModel> {
        const request = new ApiDetailsRequest(this.groups, TemplateDetailModel, itemId);
        return this.apiRequestService.findOne(request);
    }

    /**
     * Check if a template is in use
     * @param {string} templateId
     * @returns {Observable<TemplateUsageModel>}
     */
    public getTemplateUsage(templateId: string): Observable<TemplateUsageModel> {
        const request = new ApiGetRequest([...this.groups, templateId, ApiConstants.API_METHOD_USAGE], TemplateUsageModel)
        return this.apiRequestService.get(request);
    }
}
