import {inject, Injectable} from '@angular/core';
import {ApiRequestService} from './api-request.service';
import {Observable} from 'rxjs';
import {ARPagedResponseDataModel} from '@relayter/core';
import {ApiPagedRequest} from '../requests/api-paged.request';
import {ApiPatchRequest} from '../requests/api-patch.request';
import {ApiDetailsRequest} from '../requests/api-details.request';
import {ApiDeleteOneRequest} from '../requests/api-delete-one.request';
import {ApiPostRequest} from '../requests/api-post.request';
import {TableSortOptions} from '../table-sort-options';
import {NewCursor} from '../new-api-cursor';
import {QueryParams} from '../../classes/query-params';
import {ApiPutRequest} from '../requests/api-put.request';

@Injectable({
    providedIn: 'root'
})
export abstract class ApiService<T> {
    groups: string[] = [];
    model: new (...args: any[]) => T;
    protected apiRequestService = inject(ApiRequestService);

    protected constructor(groups: string[], model: new (...args: any[]) => T) {
        this.groups = groups;
        this.model = model;
    }

    public findOne(itemId: string): Observable<T> {
        const request = new ApiDetailsRequest(this.groups, this.model, itemId);
        return this.apiRequestService.findOne(request);
    }

    public create(body:  Record<string, any>): Observable<T> {
        const request = new ApiPostRequest(this.groups, this.model, body);
        return this.apiRequestService.create<T>(request);
    }

    public deleteOne(itemId: string): Observable<boolean> {
        const request = new ApiDeleteOneRequest(this.groups, itemId);
        return this.apiRequestService.deleteOne(request);
    }

    public find(limit?: number,
                offset?: number,
                sortColumns?: TableSortOptions,
                cursor?: NewCursor,
                search?: string,
                filters?: Record<string, any>): Observable<ARPagedResponseDataModel<T>> {
        const request = new ApiPagedRequest(this.groups, this.model, limit, offset, sortColumns, cursor, search, filters);
        return this.apiRequestService.find(request);
    }

    public patch(id: string, body: Record<string, any>): Observable<T> {
        const request = new ApiPatchRequest(this.groups, this.model, id, body);
        return this.apiRequestService.patch<T>(request);
    }

    public put(id: string, body: Record<string, any>): Observable<T> {
        const request = new ApiPutRequest(this.groups, this.model, id, body);
        return this.apiRequestService.put<T>(request);
    }

    public findAll(queryParams?: QueryParams): Observable<T[]> {
        const request = new ApiPagedRequest(this.groups, this.model);
        if (queryParams) {
            request.additionalQueryParams.addObject(queryParams.getParams());
        }

        return this.apiRequestService.findAll(request);
    }
}
