import {DestroyRef, inject, Injectable, OnDestroy, signal, WritableSignal} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';
import {WorkflowLayoutItem} from '../../../../../../models/api/custom-workflow/workflow-layout-item.model';
import {CustomWorkflowService} from '../custom-workflow.service';
import {EItemSelectionType, ItemSelectionState} from './custom-workflow-item-selection.component';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {UserSettingsStorageService} from '../../../../../../api/services/user-settings-storage.service';
import {SortDirection} from '@angular/material/sort';
import {PublicationModel} from '../../../../../../models/api/publication.model';

export enum EPublicationItemSortProperty {
    PUBLICATION_ITEM_ID = 'publicationItemId',
    FIRST_PAGE_NUMBER = 'firstPageNumber',
    ID = '_id',
}

export interface IPublicationItemSort {
    sortProperty: EPublicationItemSortProperty;
    sortOrder: SortDirection;
}

export class UpdatedWorkflowLayoutItemData {
    constructor(public workflowLayoutItem: WorkflowLayoutItem, public transitionItemId?: string) {
    }
}

@Injectable()
export class PublicationItemSelectionService implements OnDestroy {
    private destroyRef = inject(DestroyRef);
    private static readonly PUBLICATION_ITEM_SELECTION_SORT: string = 'publication_item_selection_sort';

    private selectedWorkflowLayoutItem = new BehaviorSubject<WorkflowLayoutItem>(null);
    public selectedWorkflowLayoutItem$ = this.selectedWorkflowLayoutItem.asObservable();

    private onNextClicked = new Subject<void>();
    public onNextClicked$ = this.onNextClicked.asObservable();

    private onBackClicked = new Subject<void>();
    public onBackClicked$ = this.onBackClicked.asObservable();

    public isFirstItem: WritableSignal<boolean> = signal(true);

    public isLastItem: WritableSignal<boolean> = signal(true);

    private updatedWorkflowLayoutItem = new Subject<UpdatedWorkflowLayoutItemData>();
    public updatedWorkflowLayoutItem$ = this.updatedWorkflowLayoutItem.asObservable();

    private onWorkflowLayoutItemsChanged = new Subject<void>();
    public onWorkflowLayoutItemsChanged$ = this.onWorkflowLayoutItemsChanged.asObservable();

    private redirectPublicationItemId = new BehaviorSubject<string>(null);

    private onNavigateToPublicationItem = new Subject<string>();
    public onNavigateToPublicationItem$ = this.onNavigateToPublicationItem.asObservable();

    // Item selection state
    private itemSelectionState = new BehaviorSubject<ItemSelectionState>(new ItemSelectionState());

    private itemSelectionType = new BehaviorSubject<EItemSelectionType>(EItemSelectionType.ITEM_SELECTION);
    public itemSelectionType$ = this.itemSelectionType.asObservable();

    private _itemsChanged: boolean;
    public set itemsChanged(value: boolean) {
        this._itemsChanged = value;
    }
    public get itemsChanged() {
        return this._itemsChanged;
    }

    private subjects = [
        this.selectedWorkflowLayoutItem,
        this.onNextClicked,
        this.onBackClicked,
        this.updatedWorkflowLayoutItem,
        this.onWorkflowLayoutItemsChanged,
        this.redirectPublicationItemId,
        this.onNavigateToPublicationItem,
        this.itemSelectionState,
        this.itemSelectionType
    ];

    constructor(private customWorkflowService: CustomWorkflowService,
                private userSettingsStorageService: UserSettingsStorageService) {
        this.customWorkflowService.activeStep$
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(() => this.resetState());
    }

    public ngOnDestroy(): void {
        this.subjects.forEach((subject) => subject.complete());
    }

    public setSelectedWorkflowLayoutItem(workflowLayoutItem?: WorkflowLayoutItem, items: WorkflowLayoutItem[] = [], hasNext: boolean = false): void {
        this.selectedWorkflowLayoutItem.next(workflowLayoutItem);
        const isFirstItem = !workflowLayoutItem || workflowLayoutItem.index === items[0]?.index;
        this.isFirstItem.set(isFirstItem);
        const isLastItem = !workflowLayoutItem ||
            (items.findIndex((item) => item.index === workflowLayoutItem.index) === (items.length - 1) && !hasNext);
        this.isLastItem.set(isLastItem);
    }

    public getSelectedWorkflowLayoutItem(): WorkflowLayoutItem {
        return this.selectedWorkflowLayoutItem.getValue();
    }

    public setUpdatedWorkflowLayoutItem(data: UpdatedWorkflowLayoutItemData): void {
        this.updatedWorkflowLayoutItem.next(data);
    }

    public onNextClick(): void {
        this.onNextClicked.next();
    }

    public onBackClick(): void {
        this.onBackClicked.next();
    }

    public onWorkflowLayoutItemsChange(): void {
        this.onWorkflowLayoutItemsChanged.next();
    }

    public setRedirectPublicationItemId(itemId: string): void {
        this.redirectPublicationItemId.next(itemId);
    }

    public getRedirectPublicationItemId(): string {
        return this.redirectPublicationItemId.getValue();
    }

    public navigateToPublicationItem(publicationItemId: string): void {
        this.onNavigateToPublicationItem.next(publicationItemId);
    }

    public getItemSelectionState(): ItemSelectionState {
        return this.itemSelectionState.getValue();
    }

    public setItemSelectionState(state: ItemSelectionState): void {
        this.itemSelectionState.next(state);
    }

    public resetState(): void {
        const currentState = this.getItemSelectionState();
        const newState = new ItemSelectionState();
        newState.itemSelectionType = currentState.itemSelectionType;

        this.itemSelectionState.next(newState);
        this.selectedWorkflowLayoutItem.next(null);
    }

    public setItemSelectionType(itemSelectionType: EItemSelectionType): void {
        this.itemSelectionType.next(itemSelectionType);
    }

    public storeSort(publication: PublicationModel, sortProperty: EPublicationItemSortProperty, sortOrder: SortDirection): void {
        let storageConfig = this.userSettingsStorageService.loadSettings(PublicationItemSelectionService.PUBLICATION_ITEM_SELECTION_SORT);
        if (!storageConfig) storageConfig = {};
        // per publication name we store a sorting config
        storageConfig[publication.name] = {sortProperty, sortOrder};
        this.userSettingsStorageService.storeSettings(PublicationItemSelectionService.PUBLICATION_ITEM_SELECTION_SORT, storageConfig);
    }

    public getStoredSort(publication: PublicationModel): IPublicationItemSort {
        let storageConfig = this.userSettingsStorageService.loadSettings(PublicationItemSelectionService.PUBLICATION_ITEM_SELECTION_SORT);
        if (!storageConfig) storageConfig = {};
        if (!storageConfig[publication.name]) {
            const defaultSortingProperty = publication.workflow.sequentialNumbering
                ? EPublicationItemSortProperty.FIRST_PAGE_NUMBER
                : EPublicationItemSortProperty.PUBLICATION_ITEM_ID;
            storageConfig[publication.name] = {sortProperty: defaultSortingProperty, sortOrder: 'asc'};
            this.userSettingsStorageService.storeSettings(PublicationItemSelectionService.PUBLICATION_ITEM_SELECTION_SORT, storageConfig);
        }
        return storageConfig[publication.name];
    }
}
