import {Component, DestroyRef, inject, Inject, OnInit} from '@angular/core';
import {BUTTON_TYPE, ButtonConfig, FullModalActionModel, NUC_DIALOG_CUSTOM_CONTENT_DATA, NucDialogCustomContentService} from '@relayter/rubber-duck';
import {TemplateUsageModel} from '../../../../../models/api/template-usage.model';
import {TemplateModel} from '../../../../../models/api/template.model';
import {AppConstants} from '../../../../../app.constants';
import {IDropdownRequestDataEvent} from '@relayter/rubber-duck/lib/interfaces/idropdown-item';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {Subscription} from 'rxjs';
import {Toaster} from '../../../../../classes/toaster.class';
import {IListGroup} from '../../../../../components/dialog/list/list-dialog.component';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {DialogButtonConfig} from '@relayter/rubber-duck/lib/modules/dialog/config/button-config.model';
import {distinctUntilChanged, finalize, switchMap} from 'rxjs/operators';
import {ETemplateJobType, TemplatesApiService} from '../../../../../api/services/templates-api.service';
import {JobModel} from '../../../../../models/api/job.model';
import {UserIsAllowedToPipe} from '../../../../../pipes/user-is-allowed-to.pipe';
import {EFormStatus} from '../../../../../app.enums';

export interface ITemplateInUseDialogData {
    template: TemplateModel;
    templateUsage: TemplateUsageModel;
}

@Component({
    selector: 'static-content-static-content-template-in-use-dialog-component',
    templateUrl: './static-content-template-in-use-dialog.component.html',
    styleUrl: './static-content-template-in-use-dialog.component.scss'
})
export class StaticContentTemplateInUseDialogComponent implements OnInit {
    private destroyRef = inject(DestroyRef);
    public listGroups: IListGroup[] = [];
    public template: TemplateModel;
    public templates: TemplateModel[] = [];
    public totalTemplates: number;
    private subscription: Subscription;
    public pageSize = AppConstants.PAGE_SIZE_DEFAULT;
    public form = new FormGroup({
        templateToMigrate: new FormControl<TemplateModel>(null, [Validators.required])
    });

    private deleteButtonConfig: DialogButtonConfig;
    public readonly PERMISSIONS = AppConstants.PERMISSIONS;

    constructor(private templatesApiService: TemplatesApiService,
                private userIsAllowedToPipe: UserIsAllowedToPipe,
                private dialogCustomContentService: NucDialogCustomContentService,
                @Inject(NUC_DIALOG_CUSTOM_CONTENT_DATA) private modalData: ITemplateInUseDialogData) {
        this.template = this.modalData.template;
        const templateUsage = this.modalData.templateUsage;

        if (templateUsage.campaigns.length > 0) {
            this.listGroups.push({
                title: 'Campaigns',
                list: templateUsage.campaigns.filter((v, i) => i < 100).map((campaign) => campaign.name)
            } as IListGroup);
        }
        if (templateUsage.workflowAutomations.length > 0) {
            this.listGroups.push({
                title: 'Workflow automations',
                list: templateUsage.workflowAutomations.filter((v, i) => i < 100).map((workflowAutomation) => workflowAutomation.name)
            } as IListGroup);
        }

    }

    public ngOnInit(): void {
        this.getTemplates({limit: this.pageSize, offset: 0});
        this.addButtons();
        this.form.statusChanges
            .pipe(distinctUntilChanged(), takeUntilDestroyed(this.destroyRef))
            .subscribe((status) => {
                this.deleteButtonConfig.disabled = status !== EFormStatus.VALID;
            });
    }

    public getTemplates(event: IDropdownRequestDataEvent): void {
        if (this.subscription && !this.subscription.closed) this.subscription.unsubscribe();
        if (event.reset) this.templates = [];

        this.subscription = this.templatesApiService.getTemplates(
            event.limit,
            event.offset,
            null,
            null,
            event.search,
            {
                engineTypes: this.template.engineType ? [this.template.engineType] : null,
                channel: this.template.channel,
                numberOfPages: this.template.staticContent.numberOfPages
            })
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: (result) => {
                    this.templates = this.templates.concat(result.items.filter((template) => template._id !== this.template._id));
                    this.totalTemplates = result.total;
                },
                error: Toaster.handleApiError
            });
    }

    private addButtons(): void {
        const cancelButtonConfig = new ButtonConfig(BUTTON_TYPE.SECONDARY, 'Cancel');
        const cancelButton = new FullModalActionModel(cancelButtonConfig);

        this.deleteButtonConfig = new ButtonConfig(BUTTON_TYPE.DESTRUCTIVE, 'Delete', false, false, true);
        const deleteButton = new FullModalActionModel(this.deleteButtonConfig);

        this.dialogCustomContentService.addDialogAction(cancelButton);
        cancelButton.observable.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => this.dialogCustomContentService.close());

        if (this.userIsAllowedToPipe.transform(this.PERMISSIONS.POST_TEMPLATE_JOBS)) {
            this.dialogCustomContentService.addDialogAction(deleteButton);
            deleteButton.observable
                .pipe(
                    switchMap(() => {
                        this.deleteButtonConfig.loading = true;
                        const jobData = {
                            templateId: this.template._id,
                            templateIdToMigrate: this.form.value.templateToMigrate.getValue()
                        };
                        return this.templatesApiService.postJob(ETemplateJobType.DELETE_TEMPLATE_JOB, jobData);
                    }),
                    finalize(() => this.deleteButtonConfig.loading = false),
                    takeUntilDestroyed(this.destroyRef)
                ).subscribe({
                next: (result: JobModel) => {
                    this.dialogCustomContentService.close(result);
                },
                error: Toaster.handleApiError
            });
        }
    }
}
