<div class="account-details">
    <div class="header-row">
        <h1>Account details</h1>
        <nuc-button-bar>

            @if (formGroup && formChanged()) {
                <nuc-button-primary text="Save changes"
                                    [disabled]="!formGroup.valid"
                                    (click)="updateAccount()">
                </nuc-button-primary>
            }

            @if (user?._id === team?.owner._id) {
                <nuc-button-secondary text="Transfer ownership"
                                      (click)="onTransferOwnershipButtonClicked()">
                </nuc-button-secondary>
            }

            <nuc-button-secondary text="Change password"
                                  (click)="onChangePasswordButtonClicked()">
            </nuc-button-secondary>
        </nuc-button-bar>
    </div>

    @if (user) {
        <h2>General information</h2>

        <form [formGroup]="formGroup" class="general-information-form">
            <nuc-form-field label="Full Name">
                <nuc-input formControlName="fullName"></nuc-input>
            </nuc-form-field>

            <nuc-form-field label="Email Address">
                <nuc-input formControlName="email"></nuc-input>
            </nuc-form-field>
        </form>
    }

</div>

<rl-relayter-version></rl-relayter-version>
