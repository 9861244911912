import {Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ARApiError} from '@relayter/core';
import {Toaster} from '../../../../../../../classes/toaster.class';
import {PublicationModel} from '../../../../../../../models/api/publication.model';
import {Subject} from 'rxjs';
import {CampaignItemModel} from '../../../../../../../models/api/campaign-item.model';
import {UpdateAssignableCampaignItemModel} from '../../../../../../../models/api/update-assignable-campaign-item.model';
import {Deserialize} from 'cerialize';
import {
    IListDialogData,
    IListGroup,
    ListDialogComponent
} from '../../../../../../../components/dialog/list/list-dialog.component';
import {
    BUTTON_TYPE,
    ButtonConfig,
    DialogCustomContentConfig,
    ESelectionMode,
    FullModalActionModel,
    FullModalService,
    ITableColumn,
    NUC_FULL_MODAL_DATA,
    NucDialogCustomContentService
} from '@relayter/rubber-duck';
import {takeUntil} from 'rxjs/operators';
import {PublicationsService} from '../../../../../../../api/services/publications.service';
import {OverlayButtonComponent} from '../../../../../../../components/overlay-button/overlay-button.component';
import {AdvancedFiltersDataService} from '../../../../../../../api/services/advanced-filters.data-service';
import {RLTableComponent} from '../../../../../../../components/rl-base-component/rl-table.component';
import {UserSettingsStorageService} from '../../../../../../../api/services/user-settings-storage.service';
import {SelectionModel} from '@angular/cdk/collections';
import {PaginatorService} from '../../../../../../../components/paginator/paginator.service';
import {PublicationItemModel} from '../../../../../../../models/api/publication-item.model';

export interface IAssignableItemsOverviewModalData {
    publication: PublicationModel;
    publicationItem: PublicationItemModel;
    campaignId: string;
}

@Component({
    selector: 'custom-workflow-assignable-items-overview-component',
    templateUrl: './custom-workflow-assignable-items-overview.component.html',
    styleUrls: ['./custom-workflow-assignable-items-overview.component.scss'],
    providers: [AdvancedFiltersDataService, PaginatorService]
})
export class CustomWorkflowAssignableItemsOverviewComponent extends RLTableComponent implements OnInit, OnDestroy {
    public readonly tableId = 'briefing-assignable';
    public additionalColumns: ITableColumn[] = [{
        title: 'Assigned to',
        key: 'assignedPublicationItems',
        format: (value: Record<string, any>[]) => {
            const assignedPages: string[] = value.map((item) => {
                item = Object.assign(new PublicationItemModel(), item);
                return item.formattedPageNumbers;
            });
            return assignedPages.length === 0 ? 'Not assigned' : assignedPages.join(', ');
        },
        sortProperty: 'firstPageNumber'
    }];

    public campaignId: string;
    public publication: PublicationModel;
    public printPublicationItem: PublicationItemModel;

    public ESelectionMode = ESelectionMode;

    public toUpdateItems: UpdateAssignableCampaignItemModel[] = [];
    private saveButton: ButtonConfig;

    // Overlay Button
    @ViewChild(OverlayButtonComponent) public overlayButton: OverlayButtonComponent;
    private onDestroySubject = new Subject<void>();

    private initialSelection: CampaignItemModel[] = [];
    public selection = new SelectionModel<CampaignItemModel>(true);

    constructor(private dialogCustomContentService: NucDialogCustomContentService,
                private publicationsService: PublicationsService,
                private fullModalService: FullModalService,
                @Inject(NUC_FULL_MODAL_DATA) modalData: IAssignableItemsOverviewModalData,
                userSettingsStorageService: UserSettingsStorageService) {
        super(userSettingsStorageService);

        this.publication = modalData.publication;
        this.printPublicationItem = modalData.publicationItem;
        this.campaignId = modalData.campaignId;
    }

    /**
     * On init get campaign id and initialize table
     */
    public ngOnInit(): void {
        this.initModalButtons();
        this.selection.changed.pipe(takeUntil(this.onDestroySubject)).subscribe(() => this.onSelectionChanged());
    }

    public ngOnDestroy(): void {
        this.onDestroySubject.next();
        this.onDestroySubject.complete();
    }

    public onSelectionChanged(): void {
        const toAdd = this.selection.selected.filter((item) => !this.initialSelection.find(initItem => initItem._id === item._id));
        const toRemove = this.initialSelection.filter((item) => !this.selection.isSelected(item));

        const newSelection = new Set<UpdateAssignableCampaignItemModel>();
        toAdd.forEach((item) => {
            newSelection.add(Deserialize({
                campaignItem: item,
                active: true
            }, UpdateAssignableCampaignItemModel));
        });

        toRemove.forEach((item) => {
            newSelection.add(Deserialize({
                campaignItem: item,
                active: false
            }, UpdateAssignableCampaignItemModel));
        });

        this.toUpdateItems = [...newSelection];
        this.saveButton.disabled = !this.toUpdateItems.length;
    }

    /**
     * Checks if selected page is already assigned to a campaign item
     */
    private isSelected(campaignItem: CampaignItemModel): boolean {
        return campaignItem.assignedPublicationItems.some((item) => {
            return item._id === this.printPublicationItem._id;
        });
    }

    public setSelectedItems(items: CampaignItemModel[]): void {
        const selectedItems = items
            .filter((item) => this.isSelected(item));

        // Hold currently selected items cross pages
        this.initialSelection = [...new Set([...selectedItems, ...this.initialSelection])];

        // Update current selection with unsaved changes
        const currentSelection = [...this.initialSelection];

        const addedItems = this.toUpdateItems.filter((item) => item.active).map(item => item.campaignItem);
        const removedItems = this.toUpdateItems.filter(((item) => !item.active)).map(item => item.campaignItem);

        removedItems.forEach((removedItem) => {
            const index = currentSelection.findIndex((item) => item._id === removedItem._id);
            if (index >= 0) {
                currentSelection.splice(index, 1);
            }
        });
        currentSelection.push(...addedItems);

        this.selection.setSelection(...currentSelection);
    }

    /**
     * Gets count of campaign items assigned to page
     */
    public getCountAssignedItems(): number {
        return this.toUpdateItems.filter((items) => items.active).length;
    }

    /**
     * Gets count of campaign items unassigned from page
     */
    public getCountUnassignedItems(): number {
        return this.toUpdateItems.filter((items) => !items.active).length;
    }

    public openApplyAssignedCampaignItemsDialog(): void {
        const listGroup = {list: []} as IListGroup;

        if (this.getCountAssignedItems() > 0) {
            listGroup.list.push(`Add ${this.getCountAssignedItems()} briefing item(s)`);
        }
        if (this.getCountUnassignedItems() > 0) {
            listGroup.list.push(`Remove ${this.getCountUnassignedItems()} briefing item(s)`);
        }

        const dialogData: IListDialogData = {
            listGroups: [listGroup],
            actionButton: {
                name: 'Apply',
                type: BUTTON_TYPE.PRIMARY
            }
        };

        const assignDialogConfig = new DialogCustomContentConfig('You are about to update this page', null, dialogData);
        const dialog = this.dialogCustomContentService.open(ListDialogComponent, assignDialogConfig);
        dialog.afterClosed().subscribe((applyClicked) => {
            if (applyClicked) {
                const campaignItems = this.printPublicationItem.campaignItems.map((item) => item._id);
                for (const item of this.toUpdateItems) {
                    if (!item.active) {
                        campaignItems.splice(campaignItems.indexOf(item.campaignItem._id), 1);
                    } else if (campaignItems.indexOf(item.campaignItem._id) === -1) {
                        campaignItems.push(item.campaignItem._id);
                    }
                }
                const body = {
                    campaignItems
                };
                this.patchPublicationItem(body);
            }
        });
    }

    /**
     * Patch campaignItems of a publication item
     */
    public patchPublicationItem(body: Record<string, any>): void {
        this.saveButton.loading = true;
        this.publicationsService.patchPublicationItem(
            body,
            this.publication._id,
            this.printPublicationItem._id).subscribe({
            next: () => {
                this.saveButton.loading = false;
                Toaster.success('Campaign items assigned successfully');
                this.fullModalService.close(true);
            },
            error: (err: ARApiError) => {
                this.saveButton.loading = false;
                Toaster.handleApiError(err);
                this.fullModalService.close(false);
            }
        });
    }

    private initModalButtons(): void {
        const cancelAction = new FullModalActionModel(new ButtonConfig(BUTTON_TYPE.SECONDARY, 'Cancel'));
        this.saveButton = new ButtonConfig(BUTTON_TYPE.PRIMARY, 'Apply selection', null, null, true);
        const saveAction = new FullModalActionModel(this.saveButton);

        cancelAction.observable.subscribe(() => this.fullModalService.close(null, true));
        saveAction.observable.subscribe(() => this.openApplyAssignedCampaignItemsDialog());

        this.fullModalService.setModalActions([cancelAction, saveAction]);
    }
}
