import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
    selector: 'upload-item-file-input-component',
    templateUrl: './upload-item-file-input.component.html',
    styleUrls: ['./upload-item-file-input.component.scss']
})
export class UploadItemFileInputComponent {
    @Input() public fileUploadTitle = 'Files';
    @Input() public dragTitle = 'files';
    @Input() public subTitle: string;
    @Input() public multiple = true;
    @Input() public active: boolean;
    @Output() public filesChanged = new EventEmitter<File[]>();

    /**
     * Responds to file(s) drops
     * @param {File[]} files
     */
    public onFileChanged(files: File[]): void {
        this.filesChanged.emit(files);
    }
}
