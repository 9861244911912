import {MasterPageModel} from '../../../../../../../../models/api/master-page.model';
import {IEditorOptions} from '../spread-editor.component';
import {TemplateModel} from '../../../../../../../../models/api/template.model';

export class BackgroundStagePixi extends PIXI.Container {

    private masterPageSprite: PIXI.Sprite;
    private loader = new PIXI.loaders.Loader();

    constructor(private editorOptions: IEditorOptions) {
        super();
    }

    public reset(): void {
        this.loader.reset();
        if (this.masterPageSprite) {
            this.masterPageSprite.destroy();
        }
    }

    public setDefaultBackground(template: TemplateModel): void {
        const backgroundGraphic = new PIXI.Graphics();

        backgroundGraphic.beginFill(0xffffff, 1);
        backgroundGraphic.drawRect(0, 0, template.staticContent.pageSize.width * template.staticContent.numberOfPages,
            template.staticContent.pageSize.height);
        backgroundGraphic.endFill();

        this.addChild(backgroundGraphic);
    }

    public setMasterPage(masterPage: MasterPageModel): void {
        this.loader.add(masterPage.files.preview);
        this.loader.load((loader, resources) => {
            this.masterPageSprite = new PIXI.Sprite(resources[masterPage.files.preview]['texture']);
            this.masterPageSprite.width = masterPage.pageSize.width * masterPage.pageCount;
            this.masterPageSprite.height = masterPage.pageSize.height;
            this.addChild(this.masterPageSprite);
        });
    }

}
