import {autoserialize, autoserializeAs, inheritSerialization} from 'cerialize';
import {StaticContentRulesetBaseItem} from './static-content-ruleset-item.model';
import {ConditionGroupsModel} from '../../../../models/api/condition-groups.model';
import {ApiConstants} from '../../../../api/api.constant';

export class AssetFormatModel {
    @autoserialize public displayFormat: string;
    @autoserializeAs((t) => String(t)) public horizontalMargin: string;
    @autoserializeAs((t) => String(t)) public verticalMargin: string;

    constructor(
        displayFormat: string,
        horizontalMargin: string,
        verticalMargin: string) {
        this.displayFormat = displayFormat;
        this.horizontalMargin = horizontalMargin;
        this.verticalMargin = verticalMargin;
    }
}

@inheritSerialization(StaticContentRulesetBaseItem)
export class StaticContentRulesetAssetItemModel extends StaticContentRulesetBaseItem {
    @autoserialize public _id: string;
    @autoserializeAs((t) => String(t)) public x: string;
    @autoserializeAs((t) => String(t)) public y: string;
    @autoserializeAs((t) => String(t)) public width: string;
    @autoserializeAs((t) => String(t)) public height: string;
    @autoserializeAs((t) => Number(t)) public limit: number;
    @autoserializeAs((t) => Number(t)) public skip: number;
    @autoserializeAs(AssetFormatModel) public format: AssetFormatModel;

    constructor(name: string,
                layer: string,
                x: string,
                y: string,
                width: string,
                height: string,
                format: AssetFormatModel,
                limit: number,
                skip: number,
                conditions: ConditionGroupsModel) {
        super(name, layer, conditions);
        this.x = x;
        this.y = y;
        this.width = width;
        this.height = height;
        this.format = format;
        this.limit = limit;
        this.skip = skip;
    }

    public getApiPath(): string {
        return ApiConstants.API_METHOD_ASSET_ITEMS;
    }
}
