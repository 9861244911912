<ng-container *ngIf="tabbarItems.length > 1">
    <nuc-tab-bar [tabs]="tabbarItems" [(activeTab)]="selectedTab"></nuc-tab-bar>
    <hr class="tab-bar-hr"/>
</ng-container>

<ng-container [ngSwitch]="selectedTab.index">
    <ng-container *ngSwitchCase="TAB_TEMPLATES" [ngTemplateOutlet]="templateSelection"></ng-container>
    <ng-container *ngSwitchCase="TAB_PACKAGE_DATA" [ngTemplateOutlet]="packageData"></ng-container>
    <ng-container *ngSwitchCase="TAB_LAYOUT_NOTES" [ngTemplateOutlet]="layoutNotes"></ng-container>
</ng-container>

<ng-template #noPermissionState>
    <nuc-empty-state-component imageUrl="assets/images/empty_states/no_permission_small.svg"
                               [small]="true"
                               title="No permission">
    </nuc-empty-state-component>
</ng-template>

<ng-template #templateSelection>
    <div class="template-selection-container" *ngIf="(permissions.GET_TEMPLATES | userIsAllowedTo); else noPermissionState">
        <multi-item-selection-component [tableId]="tableId"
                                        [columns]="columns"
                                        [loading]="!templateSubscription?.closed"
                                        [selectedItems]="selectedTemplates"
                                        (selectedItemsChange)="onSelectionChanged($event)"
                                        [items]="templates"
                                        [disableNextPage]="disableNextPage"
                                        [noResultsTitle]="!!searchValue || !!selectedTemplateType ? 'No template matches your search criteria.' : null"
                                        (onSortOptionChange)="onSortOptionChanged($event)"
                                        selectionTitle="templates">
            <div button-bar-left class="filter-container">
                <nuc-input prefix="nucicon_search"
                           [data]="searchValue"
                           (debounceValueChanged)="onSearchBarValueUpdated($event)"
                           placeholder="Search">
                </nuc-input>

                <nuc-dropdown class="dropdown"
                              (requestData)="getTemplateTypes($event.limit, $event.offset)"
                              [total]="totalTemplateTypes"
                              [items]="templateTypes"
                              [formControl]="templateTypeControl"
                              placeholder="Select template type">
                </nuc-dropdown>
            </div>
        </multi-item-selection-component>
    </div>
</ng-template>

<ng-template #packageData>
    <div class="package-data-container" *ngIf="(permissions.GET_PACKAGES | userIsAllowedTo) else noPermissionState">
        <ng-container *ngIf="packages?.length else otherPackageState">
            <nuc-button-bar>
                <div class="dropdown">
                    <p class="label">Campaign package</p>
                    <nuc-dropdown [total]="totalPackages"
                                  (requestData)="getPackages($event.limit, $event.offset)"
                                  [formControl]="packageControl"
                                  [items]="packages"
                                  placeholder="Select a package">
                    </nuc-dropdown>
                </div>
            </nuc-button-bar>

            <form class="template-data" *ngIf="packageDataForm; else otherFormState" [formGroup]="packageDataForm">
                <ng-container *ngFor="let template of selectedTemplates">
                    <p class="section-header">Template: {{template.name}}</p>
                    <div [formGroupName]="template._id"
                          *ngFor="let dataField of selectedPackage.packageSetup.materialDataFields">
                        <nuc-form-field [label]="dataField.name">
                            <nuc-input [formControlName]="dataField.fieldName"
                                       [placeholder]="dataField.name"></nuc-input>
                        </nuc-form-field>
                    </div>
                </ng-container>
            </form>

        </ng-container>
    </div>

    <ng-template #otherPackageState>
        <ng-container *ngIf="!packageSubscription?.closed; else noPackageState">
            <rl-loading-indicator></rl-loading-indicator>
        </ng-container>
        <ng-template #noPackageState>
            <nuc-empty-state-component imageUrl="assets/images/empty_states/packages_small.svg"
                                       [small]="true"
                                       title="There are no packages for this campaign. Add the first package in the campaign detail view.">
            </nuc-empty-state-component>
        </ng-template>
    </ng-template>

    <ng-template #otherFormState>
        <ng-container [ngTemplateOutlet]="noTemplateSelectedState"
                      *ngIf="!selectedTemplateIds.length; else otherPackageState"></ng-container>
        <ng-template #otherPackageState>
            <ng-container [ngTemplateOutlet]="noPackageSelectedState"
                          *ngIf="!selectedPackage; else noMaterialDataFieldState">
            </ng-container>
        </ng-template>
    </ng-template>

    <ng-template #noTemplateSelectedState>
        <nuc-empty-state-component imageUrl="assets/images/empty_states/templates_small.svg"
                                   [small]="true"
                                   title="No template selected">
        </nuc-empty-state-component>
    </ng-template>

    <ng-template #noPackageSelectedState>
        <nuc-empty-state-component imageUrl="assets/images/empty_states/packages_small.svg"
                                   [small]="true"
                                   title="No package selected, items won’t be added to any package.">
        </nuc-empty-state-component>
    </ng-template>


    <ng-template #noMaterialDataFieldState>
        <nuc-empty-state-component imageUrl="assets/images/empty_states/material-data_small.svg"
                                   [small]="true"
                                   title="Campaign package doesn't have any material data fields, however items will still be added to the package.">
        </nuc-empty-state-component>
    </ng-template>
</ng-template>

<ng-template #layoutNotes>
    <form class="notes-container" *ngIf="selectedTemplates?.length else noTemplateSelectedState" [formGroup]="layoutNotesForm">
        <ng-container *ngFor="let template of selectedTemplates">
            <h2>Template: {{template.name}}</h2>
            <div [formGroupName]="template._id">
                <nuc-form-field label="Layout note">
                    <nuc-textarea formControlName="layoutNote" placeholder="Add a layout note to the item"
                                  [noHorizontalResize]="true"></nuc-textarea>
                </nuc-form-field>
            </div>
        </ng-container>
    </form>

    <ng-template #noTemplateSelectedState>
        <nuc-empty-state-component imageUrl="assets/images/empty_states/templates_small.svg"
                                   [small]="true"
                                   title="No template selected">
        </nuc-empty-state-component>
    </ng-template>
</ng-template>
