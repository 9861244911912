import {
    AfterViewInit, ApplicationRef,
    Component, DestroyRef, effect,
    ElementRef,
    inject,
    input,
    InputSignal, model, ModelSignal, OnDestroy, output, OutputEmitterRef, signal,
    Signal,
    viewChild, viewChildren, WritableSignal
} from '@angular/core';
import {PublicationItemModel} from '../../../../../../../../models/api/publication-item.model';
import {CdkDrag, CdkDragHandle} from '@angular/cdk/drag-drop';
import {
    StickyNotesOverlayComponent, StickyOverlayDisplayOptions
} from '../sticky-notes-overlay/sticky-notes-overlay.component';
import {NgForOf, NgIf} from '@angular/common';
import {ComponentsModule} from '../../../../../../../../components/components.module';
import {NUCEmptyStateModule} from '@relayter/rubber-duck';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {CustomWorkflowPreviewDataService} from '../../custom-workflow-preview-data.service';
import {StickyNoteModel} from '../../../../../../../../models/api/sticky-note.model';
import {StickyNotesDataService} from '../../preview-sticky-notes-sidebar/sticky-notes-data.service';

@Component({
  selector: 'image-sticky-notes-view',
  standalone: true,
    imports: [
        CdkDrag,
        StickyNotesOverlayComponent,
        CdkDragHandle,
        NgForOf,
        ComponentsModule,
        NUCEmptyStateModule,
        NgIf
    ],
  templateUrl: './image-sticky-notes-view.component.html',
  styleUrl: './image-sticky-notes-view.component.scss'
})
export class ImageStickyNotesViewComponent implements AfterViewInit, OnDestroy {
    private destroyRef: DestroyRef = inject(DestroyRef);
    private previewDataService: CustomWorkflowPreviewDataService = inject(CustomWorkflowPreviewDataService);
    private stickyNotesDataService: StickyNotesDataService = inject(StickyNotesDataService);
    private appRef: ApplicationRef = inject(ApplicationRef);
    private isDestroyed = false;

    public drag: Signal<CdkDrag> = viewChild<CdkDrag>(CdkDrag);
    public items: InputSignal<PublicationItemModel[]> = input<PublicationItemModel[]>();
    public images = viewChildren('image')
    public placeHolderImage: InputSignal<string> = input<string>('');
    public horizontal: InputSignal<boolean> = input<boolean>(false);
    public minZoomLevel: OutputEmitterRef<number> = output<number>();
    public zoomLevel: ModelSignal<number> = model<number>(1)
    public container = viewChild<ElementRef>('container');
    public imagesContainer: Signal<ElementRef> = viewChild<ElementRef>('imagesContainer');
    public overlay = input<StickyOverlayDisplayOptions>();

    private readonly MIN_ZOOM_MARGE = 0.05;
    private readonly MIN_EDGE_MARGIN = 55;

    public stickyNotes: StickyNoteModel[] = [];
    public loadingImage = false;
    public dragging: WritableSignal<boolean> = signal<boolean>(false);
    public disableZoomTransition: boolean = false;

    private containerSizeObservable: ResizeObserver = new ResizeObserver(() => {
        if (!this.isDestroyed) {
            this.fitToContainer();
        }
    });

    constructor() {
        effect(() => {
            // Call zoom function when zoomLevel changes.
            this.zoomLevel();
            this.zoom();
        });
    }

    ngAfterViewInit(): void {
        this.previewDataService.stickyNotes$
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(stickyNotes => {
                this.stickyNotes = stickyNotes;
            });
        this.containerSizeObservable.observe(this.container().nativeElement);
    }

    ngOnDestroy(): void {
        this.isDestroyed = true;
        this.containerSizeObservable.disconnect();
    }

    /**
     * Start registering panning of the preview, during this action block creation of new notes
     */
    public startDragging(): void {
        this.dragging.set(true);
    }

    /**
     * When panning ends wait 100ms before allowing to create notes to prevent creation of note when you release your mouse.
     */
    public stopDragging(): void {
        setTimeout(() => {
            this.dragging.set(false);
            this.appRef.tick();
        }, 100);
    }

    public fitToContainer(): void {
        this.zoomLevel.set(this.getMinimumZoomLevel());
        this.minZoomLevel.emit(this.zoomLevel());
        this.zoom();
        if (this.drag()?.getRootElement()) this.drag().reset();
    }

    private getNaturalSize(): { width: number; height: number } {
        if (!this.images()) return {width: 0, height: 0};
        const width: number = this.images().reduce((elementWidth, element: ElementRef) =>
            elementWidth + element.nativeElement.naturalWidth, 0) as number;
        const height = Math.max(...this.images().map((element: ElementRef) => element.nativeElement.naturalHeight));

        return {width, height};
    }

    /**
     * Will return zoom so the image is either 100% for small images or less for larger images, so it fits the container
     * @return {number}
     */
    public getMinimumZoomLevel() {
        const naturalSize = this.getNaturalSize();
        const zoom = Math.min(this.container().nativeElement.clientWidth / naturalSize.width,
            this.container().nativeElement.clientHeight / naturalSize.height);

        let edgeCaseMargin = 0;
        // if the image is smaller than the container but too large to fit within the margins, add some extra margin.
        if (this.container().nativeElement.clientWidth - naturalSize.width < this.MIN_EDGE_MARGIN ||
            this.container().nativeElement.clientHeight / naturalSize.height < this.MIN_EDGE_MARGIN) {
            edgeCaseMargin = this.MIN_ZOOM_MARGE;
        }

        // smaller images will be set to 100%
        return zoom > 1 ? 1.0 - edgeCaseMargin : zoom - this.MIN_ZOOM_MARGE;
    }

    /**
     * @private
     * Apply height based on the zoom level
     */
    private zoom(): void {
        if (this.imagesContainer()){
            if (this.horizontal) {
                const width = this.zoomLevel() * this.getNaturalSize().width;
                this.imagesContainer().nativeElement.style.setProperty('width', `${width}px`);
                this.imagesContainer().nativeElement.style.setProperty('height', 'auto');
            } else {
                const height = this.zoomLevel() * this.getNaturalSize().height;
                this.imagesContainer().nativeElement.style.setProperty('width', 'auto');
                this.imagesContainer().nativeElement.style.setProperty('height', `${height}px`);
            }
        }
    }

    public newStickyNote(newStickyNote: StickyNoteModel) {
        // Ignore mouse event for this case.
        this.stickyNotesDataService.setSelectedStickyNote(newStickyNote);
    }

}
