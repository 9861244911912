import {Component, DestroyRef, inject, Inject, OnInit} from '@angular/core';
import {TemplatesApiService} from '../../../../../../../api/services/templates-api.service';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {PublicationModel} from '../../../../../../../models/api/publication.model';
import {PublicationItemModel} from '../../../../../../../models/api/publication-item.model';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {DropdownItem} from '../../../../../../../models/ui/dropdown-item.model';
import {EEngineType, TemplateModel} from '../../../../../../../models/api/template.model';
import {TemplateTypeModel} from '../../../../../../../models/api/template-type.model';
import {Toaster} from '../../../../../../../classes/toaster.class';
import {distinctUntilChanged, finalize} from 'rxjs/operators';
import {startWith, Subscription} from 'rxjs';
import {EChannel, EFormStatus} from '../../../../../../../app.enums';
import {RLValidatorConstants} from '../../../../../../../classes/validators/rl-validators.constant';
import {
    BUTTON_TYPE,
    ButtonConfig,
    FullModalActionModel,
    FullModalService,
    NUC_FULL_MODAL_DATA
} from '@relayter/rubber-duck';
import {AppConstants} from '../../../../../../../app.constants';
import {PublicationsService} from '../../../../../../../api/services/publications.service';
import {TemplateTypeService} from '../../../../../../../api/services/template-types.service';
import {CustomWorkflowActionModel} from '../../../../../../../models/api/custom-workflow-action.model';
import {EWorkflowActionOptionName} from '../../../../../../../models/api/custom-workflow-option.model';
import {CampaignModel} from '../../../../../../../models/api/campaign.model';

export interface IPublicationItemFormComponentData {
    publication: PublicationModel;
    publicationItem?: PublicationItemModel;
    action: CustomWorkflowActionModel;
    campaign: CampaignModel
}

interface IPublicationForm {
    name: FormControl<string>;
    tags: FormControl<DropdownItem<string>[]>;
    template: FormControl<TemplateModel>;
    templateFilters: FormGroup<{
        templateType: FormControl<TemplateTypeModel>;
    }>;
}

interface IEditItemActionOptions {
    engineTypes: { name: EWorkflowActionOptionName.ENGINE_TYPES; value: EEngineType[] };
    templateFilterCampaignTags: { name: EWorkflowActionOptionName.TEMPLATE_FILTER_CAMPAIGN_TAGS; value: boolean };
    templateFilterTemplateTypes: { name: EWorkflowActionOptionName.TEMPLATE_FILTER_TEMPLATE_TYPES; value: string[] };
    templateFilterTemplateTags: { name: EWorkflowActionOptionName.TEMPLATE_FILTER_TEMPLATE_TAGS; value: string[] };
    channel: { name: EWorkflowActionOptionName.CHANNEL; value: EChannel };
}

@Component({
    selector: 'publication-item-form-component',
    templateUrl: 'publication-item-form.component.html',
    styleUrls: ['publication-item-form.component.scss']
})
// this form can be used as create & update
export class PublicationItemFormComponent implements OnInit {
    private destroyRef = inject(DestroyRef);
    private readonly publication: PublicationModel;
    private readonly publicationItemToEdit: PublicationItemModel;

    public formGroup: FormGroup<IPublicationForm>;

    public templateTypeOptions: TemplateTypeModel[] = [];
    public tagOptions: DropdownItem<string>[];

    private templateSubscription: Subscription;
    private templateIndexPage = 0;
    public totalTemplates: number;
    public templates: TemplateModel[] = [];
    private templatePageSize = AppConstants.PAGE_SIZE_DEFAULT;
    private selectedTemplateId: string;

    public editItemOptions: IEditItemActionOptions;

    private saveButton: ButtonConfig;

    constructor(@Inject(NUC_FULL_MODAL_DATA) private modalData: IPublicationItemFormComponentData,
                private fullModalService: FullModalService,
                private publicationsService: PublicationsService,
                private templatesApiService: TemplatesApiService,
                private templateTypeService: TemplateTypeService) {

        this.publication = modalData.publication;
        this.publicationItemToEdit = modalData.publicationItem;
        this.selectedTemplateId = this.publicationItemToEdit.template?._id;
    }

    public ngOnInit(): void {
        this.getActionOptions();
        this.initButtons();
        this.initForm();

        this.templateTypeService.getTemplateTypes()
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: (result) => {
                    this.templateTypeOptions = result.items;
                },
                error: Toaster.handleApiError
            });
    }

    private initButtons(): void {
        // set up the buttons for full modal
        this.saveButton = new ButtonConfig(BUTTON_TYPE.PRIMARY, 'Save', null, null, false);
        const cancelButton = new ButtonConfig(BUTTON_TYPE.SECONDARY, 'Cancel');
        const cancel = new FullModalActionModel(cancelButton);
        cancel.observable.subscribe(() => this.fullModalService.close(false, true));
        const save = new FullModalActionModel(this.saveButton);
        save.observable.subscribe(() => {
            if (this.publicationItemToEdit) {
                this.onEditClicked();
            }
        });
        this.fullModalService.setModalActions([cancel, save]);
    }

    private initForm(): void {
        this.formGroup = new FormGroup<IPublicationForm>({
            name: new FormControl({value: this.publicationItemToEdit.publicationItemId, disabled: true},
                RLValidatorConstants.VALIDATOR_SETS.REQUIRED),
            tags: new FormControl(this.publicationItemToEdit.tags.map((tag: string) => new DropdownItem(tag, tag))),
            template: new FormControl(this.publicationItemToEdit.template),
            templateFilters: new FormGroup({
                templateType: new FormControl(this.publicationItemToEdit.template?.templateType)
            })
        });

        if (this.publicationItemToEdit.template) { // only for items with template require a template
            this.formGroup.controls.template.addValidators(Validators.required);
        }

        this.formGroup.controls.template.valueChanges.pipe(
            distinctUntilChanged(),
            takeUntilDestroyed(this.destroyRef)
        ).subscribe((template: TemplateModel) => this.selectedTemplateId = template?._id);

        this.formGroup.statusChanges.pipe(
            distinctUntilChanged(),
            takeUntilDestroyed(this.destroyRef)
        ).subscribe((status) => {
            this.saveButton.disabled = status === EFormStatus.INVALID; // disable the button based on the form status
        });

        this.formGroup.controls.templateFilters.valueChanges
            .pipe(
                startWith(this.formGroup.controls.templateFilters.value),
                distinctUntilChanged(),
                takeUntilDestroyed(this.destroyRef)
            )
            .subscribe((value) => {
                this.templateIndexPage = 0;
                this.templates = [];
                if (value) this.getTemplates();
            });
    }

    private getActionOptions(): void {
        if (this.modalData?.action?.options) {
            const actionOptions = this.modalData.action.options;

            this.editItemOptions = {
                engineTypes:actionOptions.find((option) => option.name === EWorkflowActionOptionName.ENGINE_TYPES),
                channel: actionOptions.find((option) => option.name === EWorkflowActionOptionName.CHANNEL),
                templateFilterCampaignTags: actionOptions.find((option) =>
                    option.name === EWorkflowActionOptionName.TEMPLATE_FILTER_CAMPAIGN_TAGS),
                templateFilterTemplateTags: actionOptions.find((option) =>
                    option.name === EWorkflowActionOptionName.TEMPLATE_FILTER_TEMPLATE_TAGS),
                templateFilterTemplateTypes: actionOptions.find((option) =>
                    option.name === EWorkflowActionOptionName.TEMPLATE_FILTER_TEMPLATE_TYPES)
            } as IEditItemActionOptions;
        }
    }

    /**
     * when edit a publication item
     */
    private onEditClicked(): void {
        this.saveButton.loading = true;

        const tags = this.formGroup.value.tags.map((tag: DropdownItem<string>) => tag.getValue());
        const patchBody = {tags};
        if (this.formGroup.value.template?._id !== this.publicationItemToEdit?.template?._id) {
            patchBody['template'] = this.formGroup.value.template._id;
        }
        this.publicationsService.patchPublicationItem(patchBody, this.publication._id, this.publicationItemToEdit._id)
            .pipe(finalize(() => this.saveButton.loading = false), takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: () => {
                    Toaster.success('Publication item edited successfully');
                    this.fullModalService.close(true);
                },
                error: Toaster.handleApiError
            });
    }

    public onTagChanged(event: string): void {
        this.tagOptions = event && event.length && event.trim().length ? [new DropdownItem<string>(event.trim(), event.trim())] : [];
    }

    private get templateFilterCampaignTags(): string[] {
        return this.editItemOptions?.templateFilterCampaignTags?.value === true ?
            this.modalData.campaign.tags : [];
    }

    private get templateFilterTemplateTags(): string[] {
        return this.editItemOptions?.templateFilterTemplateTags?.value?.length > 0 ?
            this.editItemOptions.templateFilterTemplateTags.value : [];
    }

    private get templateFilterTemplateTypes(): string[] {
        return this.editItemOptions?.templateFilterTemplateTypes?.value?.length > 0 ?
            this.editItemOptions.templateFilterTemplateTypes.value : [];
    }

    private get channelFilter(): EChannel | null {
        return this.editItemOptions?.channel?.value?.length ?
            this.editItemOptions.channel.value : null;
    }

    private get engineTypesFilter(): EEngineType[] | null {
        return this.editItemOptions?.engineTypes?.value?.length > 0 ?
            this.editItemOptions.engineTypes.value : null;
    }

    private get selectedTemplateTypes(): string[] | null {
        const templateFiltersValue = this.formGroup.controls.templateFilters.value;
        const selectedTemplateTypeId = templateFiltersValue.templateType?.getValue();

        const selectedTemplateTypes = [...this.templateFilterTemplateTypes];
        if (selectedTemplateTypeId) selectedTemplateTypes.push(selectedTemplateTypeId);

        return selectedTemplateTypes.length > 0 ? selectedTemplateTypes : null;
    }

    public getTemplates(): void {
        if (this.templateSubscription && !this.templateSubscription.closed) return;

        const tags =  [...this.templateFilterCampaignTags, ...this.templateFilterTemplateTags];

        this.templateSubscription = this.templatesApiService.getTemplates(
            this.templatePageSize,
            this.templateIndexPage * this.templatePageSize,
            'name', 'asc',
            null,
            {
                engineTypes: this.engineTypesFilter,
                templateTypeIds: this.selectedTemplateTypes,
                channel: this.channelFilter,
                tags: tags.length ? tags : null
            })
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: (result) => {
                    this.templateIndexPage += 1;
                    this.templates = this.templates.concat(result.items);
                    this.totalTemplates = result.total;

                if (this.selectedTemplateId) {
                    const foundTemplate = this.templates.find((template) => template._id === this.selectedTemplateId);

                    // If the template was not found, and we have more pages to check, continue recursion.
                    // Otherwise, patch the form with either the foundTemplate or a null value
                    if (!foundTemplate && result.total > this.templates.length) {
                        // Unsubscribe so the closed check does not stop this recursion
                        this.templateSubscription.unsubscribe();

                        this.getTemplates();
                    } else {
                        this.formGroup.patchValue({template: foundTemplate});
                    }
                }
            },
            error: Toaster.handleApiError
        });
    }

}
