import {
    ChangeDetectionStrategy, ChangeDetectorRef,
    Component,
    DestroyRef,
    inject,
    Input,
    OnInit,
    signal,
    WritableSignal
} from '@angular/core';
import {FormArray, FormControl, FormGroup} from '@angular/forms';
import {debounceTime, distinctUntilChanged, filter} from 'rxjs/operators';
import {StaticContentTemplateEditorDataService} from '../static-content-template-editor/static-content-template-editor.data-service';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {TemplateAreaModel} from '../../../../../models/api/template-area.model';
import {RLValidatorConstants} from '../../../../../classes/validators/rl-validators.constant';
import {EFormStatus} from '../../../../../app.enums';
import {ITemplateForm} from '../static-content-template-form.component';

export interface ITemplateAreaForm {
    _id: FormControl<string>;
    position: FormGroup<{
        x: FormControl<number>;
        y: FormControl<number>;
    }>;
    size: FormGroup<{
        width: FormControl<number>;
        height: FormControl<number>;
    }>,
    columns: FormControl<number>;
    rows: FormControl<number>;
    columnGutter: FormControl<number>;
    rowGutter: FormControl<number>;
}

@Component({
    selector: 'static-content-template-content',
    templateUrl: 'static-content-template-content.component.html',
    styleUrls: ['static-content-template-content.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class StaticContentTemplateContentComponent implements OnInit {
    private destroyRef = inject(DestroyRef);
    private changeDetectionRef = inject(ChangeDetectorRef);
    @Input() formGroup: FormGroup<ITemplateForm>;

    public hoveredContentIndex: WritableSignal<number> = signal(-1);
    public selectedContentIndex: WritableSignal<number> = signal(-1);

    public contents: WritableSignal<TemplateAreaModel[]> = signal([]);
    public selectedAreaFormGroup: FormGroup<ITemplateAreaForm>;

    public areasFormArray: FormArray<FormGroup<ITemplateAreaForm>>;

    constructor(public templateEditorDataService: StaticContentTemplateEditorDataService) {
    }

    public ngOnInit(): void {
        this.areasFormArray = this.formGroup.controls.areas;

        this.templateEditorDataService.contentsSubject.pipe(
            takeUntilDestroyed(this.destroyRef),
        ).subscribe((contents) => this.contents.set(contents));

        this.templateEditorDataService.hoveredContentIndexSubject.pipe(
            takeUntilDestroyed(this.destroyRef),
            distinctUntilChanged()
        ).subscribe((hoveredContentIndex) => this.hoveredContentIndex.set(hoveredContentIndex));

        this.templateEditorDataService.selectedContentIndexSubject.pipe(
            takeUntilDestroyed(this.destroyRef),
            distinctUntilChanged()
        ).subscribe((selectedContentIndex) => {
            this.selectedContentIndex.set(selectedContentIndex);
            this.selectedAreaFormGroup = this.areasFormArray.at(selectedContentIndex) as FormGroup;
        });

        this.areasFormArray.valueChanges.pipe(
            distinctUntilChanged(),
            debounceTime(50),
            filter(() => this.areasFormArray.status === EFormStatus.VALID || this.areasFormArray.length === 0),
            takeUntilDestroyed(this.destroyRef)
        ).subscribe((formValue) => {
            this.templateEditorDataService.setContents(formValue as TemplateAreaModel[]);
        });
    }

    public onAddContentButtonClicked(): void {
        const selectedNumberOfPages = this.formGroup.controls.preset.controls.numberOfPages.value.value;
        const width = this.formGroup.controls.size.controls.width.value;
        const margins = this.formGroup.controls.size.controls.margins.value;

        const x = selectedNumberOfPages === 2 ? margins.marginEnd : margins.marginStart;

        this.areasFormArray.push(
            new FormGroup({
                _id: new FormControl(null),
                position: new FormGroup({
                    x: new FormControl(x, RLValidatorConstants.VALIDATOR_SETS.POSITIVE_NUMBER_REQUIRED),
                    y: new FormControl(margins.marginTop, RLValidatorConstants.VALIDATOR_SETS.POSITIVE_NUMBER_REQUIRED)
                }),
                size: new FormGroup({
                    width: new FormControl(
                        (width - (margins.marginStart + margins.marginEnd)) / 2,
                        RLValidatorConstants.VALIDATOR_SETS.GREATER_THAN_ZERO),
                    height: new FormControl(
                        (width - (margins.marginStart + margins.marginEnd)) / 2,
                        RLValidatorConstants.VALIDATOR_SETS.GREATER_THAN_ZERO)
                }),
                columns: new FormControl(1, RLValidatorConstants.VALIDATOR_SETS.POSITIVE_NUMBER_REQUIRED),
                rows: new FormControl(1, RLValidatorConstants.VALIDATOR_SETS.POSITIVE_NUMBER_REQUIRED),
                columnGutter: new FormControl(0, RLValidatorConstants.VALIDATOR_SETS.POSITIVE_NUMBER_REQUIRED),
                rowGutter: new FormControl(0, RLValidatorConstants.VALIDATOR_SETS.POSITIVE_NUMBER_REQUIRED)
            })
        );

        this.templateEditorDataService.setSelectedContentIndex(this.areasFormArray.controls.length - 1);
    }

    public onDeleteButtonClicked(index: number): void {
        if (this.areasFormArray.controls[index]) this.areasFormArray.removeAt(index);
        this.templateEditorDataService.setSelectedContentIndex(-1);
        this.changeDetectionRef.markForCheck();
    }

    // Event handlers
    public onContentHover(index: number): void {
        this.templateEditorDataService.setHoveredContentIndex(index);
    }

    public onContentLeave(): void {
        this.templateEditorDataService.setHoveredContentIndex(-1);
    }

    public onContentSelect(index: number): void {
        this.templateEditorDataService.setSelectedContentIndex(index);
        this.templateEditorDataService.setHoveredContentIndex(-1);
    }

    public onContentDeselect(): void {
        this.templateEditorDataService.setSelectedContentIndex(-1);
    }
}
