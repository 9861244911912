import {Component, DestroyRef, inject, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {map, pairwise, startWith} from 'rxjs/operators';
import {TemplateMarginsModel} from '../../../../../models/api/template-margins.model';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {StaticContentTemplateSizeModel} from '../static-content-template-editor/static-content-template-size.model';
import {EPageType} from '../../../../../models/api/template.model';
import {StaticContentTemplateEditorDataService} from '../static-content-template-editor/static-content-template-editor.data-service';
import {ITemplateForm} from '../static-content-template-form.component';

export interface ITemplateSizeForm {
    width: FormControl<number>;
    height: FormControl<number>;
    margins: FormGroup<{
        allMarginsTheSame: FormControl<boolean>,
        marginTop: FormControl<number>;
        marginBottom: FormControl<number>;
        marginEnd: FormControl<number>;
        marginStart: FormControl<number>;
    }>;
}
@Component({
    selector: 'static-content-template-size',
    templateUrl: 'static-content-template-size.component.html',
    styleUrls: ['static-content-template-size.component.scss']
})
export class StaticContentTemplateSizeComponent implements OnInit {
    private destroyRef = inject(DestroyRef);
    public pageType: EPageType;
    public EPageType = EPageType;

    @Input() public formGroup: FormGroup<ITemplateForm>;
    public sizeFormGroup: FormGroup<ITemplateSizeForm>;

    constructor(private templateEditorDataService: StaticContentTemplateEditorDataService) {
    }

    public ngOnInit(): void {
        this.sizeFormGroup = this.formGroup.controls.size;
        this.formGroup.controls.preset.controls.numberOfPages.valueChanges
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((selectedPageType) => {
                this.pageType = selectedPageType.label as EPageType;
            });

        this.sizeFormGroup.valueChanges.pipe(
            startWith(this.sizeFormGroup.value),
            pairwise(),
            map(([previousValue, newValue]) => {
                const allMarginsTheSame = this.sizeFormGroup.value.margins.allMarginsTheSame;

                if (allMarginsTheSame) {
                    const margin = this.getMargin(previousValue.margins as TemplateMarginsModel, newValue.margins as TemplateMarginsModel);
                    this.sizeFormGroup.get('margins.marginTop').setValue(margin, {emitEvent: false});
                    this.sizeFormGroup.get('margins.marginBottom').setValue(margin, {emitEvent: false});
                    this.sizeFormGroup.get('margins.marginEnd').setValue(margin, {emitEvent: false});
                    this.sizeFormGroup.get('margins.marginStart').setValue(margin, {emitEvent: false});
                }

                return new StaticContentTemplateSizeModel(
                    this.sizeFormGroup.value.width,
                    this.sizeFormGroup.value.height,
                    this.sizeFormGroup.value.margins as TemplateMarginsModel
                );
            }),
            takeUntilDestroyed(this.destroyRef)
        ).subscribe((newTemplateSize) => this.templateEditorDataService.setTemplateSize(newTemplateSize));
    }

    /**
     * Checks which margin has been changed, and sends back the updated margin. By default it gets marginTop.
     */
    public getMargin(previousMargins: TemplateMarginsModel, newMargins: TemplateMarginsModel): number {
        let margin = newMargins.marginTop;

        if (previousMargins.marginBottom !== newMargins.marginBottom) {
            margin = newMargins.marginBottom;
        }
        if (previousMargins.marginEnd !== newMargins.marginEnd) {
            margin = newMargins.marginEnd;
        }
        if (previousMargins.marginStart !== newMargins.marginStart) {
            margin = newMargins.marginStart;
        }

        return margin;
    }
}
