import {BehaviorSubject, Observable, of} from 'rxjs';
import {UploadModel} from '../../../../../../../components/upload-file-component/upload.model';
import {catchError, map, shareReplay, startWith} from 'rxjs/operators';
import {PublicationItemModel} from '../../../../../../../models/api/publication-item.model';
import {VariantModel} from '../../../../../../../models/api/variant.model';
import {FileTypeUtil} from '../../../../../../../classes/file-type.util';
import {EChannel} from '../../../../../../../app.enums';
import {EEngineType} from '../../../../../../../models/api/template.model';

export enum EUploadCategory {
    TEMPLATE = 'TEMPLATE',
    SOURCE = 'SOURCE',
    EXPORT = 'EXPORT',
    FILE = 'FILE'
}

export class UploadGroupModel {
    public static readonly VALIDATION_STATUS = {
        VALIDATING: 'VALIDATING',
        VALID: 'VALID',
        INVALID: 'INVALID'
    };

    public identifier: string;
    public publicationItem: PublicationItemModel;
    public publicationItem$: Observable<PublicationItemModel>;
    public uploadCategory: EUploadCategory;
    public variant: VariantModel;

    public get requiredFileExtensions(): string[] {
        const uploadFileTypes = this.uploads.map(upload => upload.fileType);

        // File is matched against a publication item, when there is a template, we need 2 files (idml and pdf/png)
        // When there is no template, we assume an asset and the required file extension is the file extension
        if (this.publicationItem) {
            if (this.publicationItem.template) {
                const template = this.publicationItem.template;
                if (template.channel === EChannel.DIGITAL && template.engineType === EEngineType.INDESIGN) {
                    return [FileTypeUtil.EXTENSIONS.PNG, FileTypeUtil.EXTENSIONS.IDML]
                }else if (template.channel === EChannel.PRINT && template.engineType === EEngineType.INDESIGN) {
                    return [FileTypeUtil.EXTENSIONS.PDF, FileTypeUtil.EXTENSIONS.IDML]
                } else if (template.channel === EChannel.DIGITAL && template.engineType === EEngineType.SVG) {
                    return [FileTypeUtil.EXTENSIONS.SVG]
                } else if (template.channel === EChannel.PRINT && template.engineType === EEngineType.SVG) {
                    return [FileTypeUtil.EXTENSIONS.PDF, FileTypeUtil.EXTENSIONS.SVG]
                } else if (template.engineType === EEngineType.AFTER_EFFECTS) {
                    return [FileTypeUtil.EXTENSIONS.AEP, FileTypeUtil.EXTENSIONS.MP4]
                }
            } else {
                return uploadFileTypes.splice(-1, 1);
            }
        }

        // No matching publication item. When idml assume we need all files, otherwise the file extension
        if (uploadFileTypes.includes(FileTypeUtil.EXTENSIONS.IDML)) {
            return [FileTypeUtil.EXTENSIONS.PNG, FileTypeUtil.EXTENSIONS.PDF, FileTypeUtil.EXTENSIONS.IDML];
        }

        // Only one file extension, so take the last one
        return uploadFileTypes.splice(-1, 1);
    }
    public get viewType(): EUploadCategory {
        return this.requiredFileExtensions.length > 1 ? EUploadCategory.TEMPLATE : EUploadCategory.FILE;
    };
    public uploads: UploadModel[] = [];
    public validationStatus$: Observable<string>;

    /**
     * @constructor
     * @param {string} identifier
     * @param {PublicationItemModel} publicationItem
     * @param {VariantModel} variant
     * @param {EUploadCategory} uploadCategory
     */
    constructor(identifier: string, publicationItem: PublicationItemModel, variant: VariantModel, uploadCategory?: EUploadCategory) {
        this.identifier = identifier;
        this.publicationItem = publicationItem;

        if (variant) this.variant = variant;
        if (uploadCategory) this.uploadCategory = uploadCategory;

        this.publicationItem$ = new BehaviorSubject<PublicationItemModel>(publicationItem);
        this.validationStatus$ = this.publicationItem$.pipe(
            catchError(() => of(null)), // Catch a connection error
            map((item) => item ? UploadGroupModel.VALIDATION_STATUS.VALID : UploadGroupModel.VALIDATION_STATUS.INVALID),
            startWith(UploadGroupModel.VALIDATION_STATUS.VALIDATING),
            shareReplay(1)
        );

    }
}
