import {DestroyRef, Directive, ElementRef, inject, OnInit, ViewChild} from '@angular/core';
import {FileTypeUtil} from '../../../../../../../classes/file-type.util';
import {PublicationsService} from '../../../../../../../api/services/publications.service';
import {BUTTON_TYPE, ButtonConfig, FullModalActionModel} from '@relayter/rubber-duck';
import {Toaster} from '../../../../../../../classes/toaster.class';
import {combineLatest, forkJoin, Observable, of, ReplaySubject} from 'rxjs';
import {map, shareReplay, startWith, switchMap, take} from 'rxjs/operators';
import {EUploadStatus} from '../../../../../../../components/upload-file-component/upload.model';
import {PublicationItemFilesModel, PublicationItemModel} from '../../../../../../../models/api/publication-item.model';
import {VariantModel} from '../../../../../../../models/api/variant.model';
import {AmazonService} from '../../../../../../../api/services/amazon.service';
import {
    KeyItemMapModel
} from '../../custom-workflow-files/custom-workflow-upload/custom-workflow-files-upload.component';
import {PublicationModel} from '../../../../../../../models/api/publication.model';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {DataFieldModel} from '../../../../../../../models/api/data-field.model';
import {EUploadCategory, UploadGroupModel} from '../../custom-workflow-files/custom-workflow-upload/upload-group.model';
import {DataFieldsApiService} from '../../../../../../../api/services/data-fields.api.service';
import {CustomWorkflowService} from '../../custom-workflow.service';
import {EEngineType} from '../../../../../../../models/api/template.model';
import {EChannel} from '../../../../../../../app.enums';

enum EUploadState {
    INIT,
    EMPTY,
    UPLOADING,
    READY
}

export interface IEventData {
    itemIds: string[];
    uploads: KeyItemMapModel[];
}

@Directive()
export abstract class UploadItemFilesFormComponent implements OnInit {
    protected readonly amazonService: AmazonService = inject(AmazonService);
    protected readonly VALIDATION_STATUS = UploadGroupModel.VALIDATION_STATUS;
    protected readonly EUploadStatus = EUploadStatus;
    protected readonly EUploadCategory = EUploadCategory;
    protected readonly destroyRef = inject(DestroyRef);

    public isInline: boolean;

    @ViewChild('fileInput') public fileInput: ElementRef;

    public allowedFileExtensions = {
        [EUploadCategory.EXPORT]: FileTypeUtil.getExtensionsFromFileCategories([
            FileTypeUtil.CATEGORIES.PDF,
            FileTypeUtil.CATEGORIES.IMAGE,
            FileTypeUtil.CATEGORIES.VIDEO]),
        [EUploadCategory.SOURCE]: FileTypeUtil.getExtensionsFromFileCategories(FileTypeUtil.DOWNLOAD_CATEGORIES),
        [EUploadCategory.FILE]: FileTypeUtil.getExtensionsFromFileCategories(FileTypeUtil.DOWNLOAD_CATEGORIES)
    };

    public publication: PublicationModel;
    public publicationItem: PublicationItemModel;
    public publicationItemFiles: PublicationItemFilesModel;
    public activeVariant: VariantModel;
    public workflowLayoutId: string;
    public stepId: string;

    public exportFileSubTitle: string;
    public sourceFileSubTitle: string;
    public attachmentsSubTitle ='Any files used in the source file.';

    protected uploadItemFiles: UploadGroupModel[] = [];
    public uploadItemFiles$ = new ReplaySubject<UploadGroupModel[]>(1);
    public publicationItem$ = new ReplaySubject<PublicationItemModel>(1);
    public dataFields: DataFieldModel[];
    protected campaignId: string;

    // Modal data
    public publicationItemId: string;
    public publicationItems: PublicationItemModel[];

    public get exportFile(): UploadGroupModel {
        return this.uploadItemFiles.find(item => item.uploadCategory == EUploadCategory.EXPORT);
    }

    public get sourceFile(): UploadGroupModel {
        return this.uploadItemFiles.find(item => item.uploadCategory == EUploadCategory.SOURCE);
    }

    public get files(): UploadGroupModel[] {
        return this.uploadItemFiles.filter(item => item.uploadCategory == EUploadCategory.FILE);
    }

    public uploadsReady$: Observable<EUploadState> = this.uploadItemFiles$.pipe(
        switchMap((uploadGroups: UploadGroupModel[]) => {
            if (uploadGroups.length === 0) {
                return of(EUploadState.EMPTY);
            }

            if (uploadGroups.some((uploadGroup) => !uploadGroup.uploads.length)) {
                return of(EUploadState.UPLOADING);
            }

            const progressDone = uploadGroups
                .map((uploadGroup) => uploadGroup.uploads)
                .reduce((acc, uploads) => [...acc, ...uploads])
                .map((upload) => upload.progress$.pipe(map((progress) => progress === EUploadStatus.Done)));

            const itemsValid = uploadGroups.map((uploadGroup) => uploadGroup.validationStatus$.pipe(
                map((status) => status === this.VALIDATION_STATUS.VALID)));

            return combineLatest([...progressDone, ...itemsValid])
                .pipe(map((results) => results.every((result) => !!result) ? EUploadState.READY : EUploadState.UPLOADING));
        }),
        startWith(EUploadState.INIT),
        shareReplay(1)
    );

    protected publicationsService: PublicationsService = inject(PublicationsService);
    protected customWorkflowService: CustomWorkflowService = inject(CustomWorkflowService);
    protected dataFieldsService: DataFieldsApiService = inject(DataFieldsApiService);

    // Buttons
    protected confirmAction: FullModalActionModel;
    protected confirmButton: ButtonConfig;
    protected secondaryAction: FullModalActionModel;
    protected actions: FullModalActionModel[] = [];

    public ngOnInit(): void {
        this.publicationItem$.pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(publicationItem => {
                this.publicationItem = publicationItem;
                this.publicationItemFiles = this.publicationItem.getVariantFiles(this.activeVariant?._id);

                if (!this.publicationItem.template) {
                    this.exportFileSubTitle = 'To generate a preview. For instance .pdf or .mp4 file.'
                    this.sourceFileSubTitle = 'Open source file. For instance .idml or .ai file.'
                } else if (this.publicationItem.template.engineType === EEngineType.INDESIGN) {
                    this.sourceFileSubTitle = 'InDesign .idml file.'
                    this.exportFileSubTitle = this.publicationItem.template.channel === EChannel.DIGITAL
                        ? 'Exported .png file.'
                        : 'Exported .pdf file'
                } else if (this.publicationItem.template.engineType === EEngineType.AFTER_EFFECTS) {
                    this.sourceFileSubTitle = 'After Effects .aep file.'
                    this.exportFileSubTitle = 'Exported .mp4 file'
                } else if (this.publicationItem.template.engineType === EEngineType.SVG) {
                    this.sourceFileSubTitle = '.svg file.'
                    this.exportFileSubTitle = this.publicationItem.template.channel === EChannel.DIGITAL
                        ? '.svg file.'
                        : 'Exported .pdf file'
                }
            });

        this.setupForm();
    }

    protected setupForm(): void {
        this.initButtons();

        this.initFormData();

        this.customWorkflowService.activeVariant$
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(variant => this.activeVariant = variant);

        this.uploadsReady$
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((uploadState) => {
                this.confirmButton.disabled = uploadState !== EUploadState.READY;
            });
    }

    protected initButtons(): void {
        this.confirmButton = new ButtonConfig(BUTTON_TYPE.PRIMARY, 'Confirm Upload', null, false, true);
        this.confirmAction = new FullModalActionModel(this.confirmButton);
        this.confirmAction.observable.subscribe(() => this.onSaveButtonClicked());

        // Used for modal
        const secondaryButton = new ButtonConfig(BUTTON_TYPE.SECONDARY, 'Cancel');
        this.secondaryAction = new FullModalActionModel(secondaryButton);
        this.secondaryAction.observable.subscribe(() => this.handleUploadData(null, true));
    }

    /**
     * Responds to file drops
     * @param {File[]} files
     * @param {EUploadCategory} itemUploadCategory
     */
    public onFilesChanged(files: File[], itemUploadCategory: EUploadCategory): void {
        if (files.length > 0) {
            let filteredFiles = files.filter(
                (file) => this.allowedFileExtensions[itemUploadCategory].some(fileExtensionGroup =>
                    fileExtensionGroup.includes(FileTypeUtil.getExtensionFromFileName(file.name))));

            if (filteredFiles.length !== files.length) {
                Toaster.warn('One or more files do not have the correct filetype');
            }

            if (itemUploadCategory === EUploadCategory.FILE) {
                const attachmentFileNames = this.publicationItem.attachments.map((attachment) => attachment.fileName);
                const result = files.reduce((acc, file) => {
                    attachmentFileNames.includes(file.name)
                        ? acc.existingFileNames.push(file.name)
                        : acc.newFiles.push(file);
                    return acc;
                }, {existingFileNames: [], newFiles: []});

                if (result.existingFileNames.length > 0) {
                    Toaster.warn(`Publication item already contains attachments with name: ${result.existingFileNames}`);
                }

                filteredFiles = result.newFiles;
            }

            const newItemGroups = this.createAndStartUploads(filteredFiles, itemUploadCategory);
            for (const newItemGroup of newItemGroups) {
                // Only one for export (preview)/source
                const uploadItemFile = this.uploadItemFiles.find(upload =>
                    upload.variant?._id === newItemGroup.variant?._id && upload.uploadCategory === itemUploadCategory &&
                    ((itemUploadCategory === EUploadCategory.FILE && upload.identifier === newItemGroup.identifier) ||
                        itemUploadCategory !== EUploadCategory.FILE));

                if (!uploadItemFile) { // uploadItemFile is new
                    this.uploadItemFiles.push(newItemGroup);
                } else { // uploadItemFile exists, update with new upload
                    uploadItemFile.uploads = newItemGroup.uploads;
                }
            }
            this.uploadItemFiles$.next(this.uploadItemFiles);
        }
    }

    /**
     * Groups files together based on filename and starts the validation & upload
     * @param {File[]} files
     * @param {EUploadCategory} uploadCategory
     * @returns {UploadGroupModel[]}
     */
    private createAndStartUploads(files: File[], uploadCategory: EUploadCategory): UploadGroupModel[] {
        const uploadGroups: UploadGroupModel[] = [];

        files.forEach((file) => {
            // Normalize the file name, because it could have another code page (selected files with the file system versus dnd)
            const uploadFile = file.name.normalize();
            let uploadGroup = uploadGroups.find((foundGroup) => foundGroup.identifier === uploadFile &&
                foundGroup.variant?._id === this.activeVariant?._id);
            if (!uploadGroup) {
                uploadGroup = new UploadGroupModel(uploadFile, this.publicationItem, this.activeVariant, uploadCategory);
                uploadGroups.push(uploadGroup);
            }

            uploadGroup.uploads.push(this.amazonService.createUpload(file));
        });

        return uploadGroups;
    }

    public onSaveButtonClicked(): void {
        // Map the upload groups to a list of s3Keys, the itemId and variantId (optional)
        const groupedS3Keys$: Observable<KeyItemMapModel>[] =
            this.uploadItemFiles
                .map((uploadGroup) =>
                    uploadGroup.uploads
                        .map((upload) =>
                            upload.s3Key$.pipe(
                                map((s3Key) =>
                                    new KeyItemMapModel(s3Key, this.publicationItem._id, this.activeVariant?._id, uploadGroup.uploadCategory)),
                                take(1))
                        )
                )
                .reduce((acc, items) => [...acc, ...items], []);

        if (groupedS3Keys$.length) {
            forkJoin(groupedS3Keys$)
                .pipe(takeUntilDestroyed(this.destroyRef))
                .subscribe(groupedS3Keys => {
                    const data = {
                        itemIds: [this.publicationItem._id],
                        uploads: groupedS3Keys
                    };
                    this.handleUploadData(data);
                });
        }
    }

    public getPublicationItem(): void {
        this.publicationsService.getPublicationItem(this.publication._id, this.publicationItemId)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: (publicationItem) => this.publicationItem$.next(publicationItem),
                error: Toaster.handleApiError
            });
    }

    public onDeleteUploadItemClicked(uploadGroup: UploadGroupModel): void {
        this.uploadItemFiles = this.uploadItemFiles.filter((uploadItemFile => uploadItemFile !== uploadGroup));
        this.uploadItemFiles$.next(this.uploadItemFiles);
    }

    protected initFormData(): void {
        this.campaignId = this.customWorkflowService.campaign?._id;

        combineLatest([
            this.customWorkflowService.publication$,
            this.customWorkflowService.workflow$,
            this.customWorkflowService.activeStep$,
            this.dataFieldsService.getCampaignItemDataFields()
        ]).pipe(takeUntilDestroyed(this.destroyRef)
        ).subscribe(([publication, workflow, step, dataFields]) => {
            this.publication = publication;
            this.workflowLayoutId = workflow.layout?._id;
            this.stepId = step._id;
            this.dataFields = dataFields;

            this.getPublicationItem();
        });
    }

    protected abstract handleUploadData(result?: IEventData, confirmClose?: boolean);
}
