import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {PublicationItemModel} from '../../../../../../../../models/api/publication-item.model';
import {EColumnType, FullModalService, ITableColumn} from '@relayter/rubber-duck';
import {CustomWorkflowFilterModel} from '../../../../../../../../models/api/custom-workflow-filter.model';
import {CustomWorkflowFilterOptionModel} from '../../../../../../../../models/api/custom-workflow-filter-option.model';
import {PublicationModel} from '../../../../../../../../models/api/publication.model';
import {CustomWorkflowStepModel} from '../../../../../../../../models/api/custom-workflow-step.model';
import {AppConstants} from '../../../../../../../../app.constants';
import {Subject, Subscription} from 'rxjs';
import {PublicationsService} from '../../../../../../../../api/services/publications.service';
import {ARApiError} from '@relayter/core';
import {Toaster} from '../../../../../../../../classes/toaster.class';
import {CursorArray} from '../../../../../../../../api/api-cursor';
import {takeUntil} from 'rxjs/operators';
import {RLTableComponent} from '../../../../../../../../components/rl-base-component/rl-table.component';
import {UserSettingsStorageService} from '../../../../../../../../api/services/user-settings-storage.service';
import {PaginatorService} from '../../../../../../../../components/paginator/paginator.service';
import {VariantModel} from '../../../../../../../../models/api/variant.model';

@Component({
    selector: 'files-download-item-selection-component',
    templateUrl: './files-download-item-selection.component.html',
    styleUrls: ['./files-download-item-selection.component.scss'],
    providers: [PaginatorService]
})
export class FilesDownloadItemSelectionComponent extends RLTableComponent implements OnInit, OnDestroy {
    private _selectedPublicationItems: PublicationItemModel[];
    public get selectedPublicationItems() {
        return this._selectedPublicationItems;
    }
    @Input() public set selectedPublicationItems(items: PublicationItemModel[]) {
        this._selectedPublicationItems = items;
        this.selectedPublicationItemsChange.emit(this._selectedPublicationItems);
    }
    @Output() public selectedPublicationItemsChange = new EventEmitter<PublicationItemModel[]>();

    @Output() public onDownloadButtonClick: EventEmitter<object> = new EventEmitter();

    @Input() public activeFilters: Map<CustomWorkflowFilterModel, CustomWorkflowFilterOptionModel[]>;
    @Input() public publication: PublicationModel;
    @Input() public step: CustomWorkflowStepModel;
    @Input() public activeVariant: VariantModel;

    public readonly tableId = 'custom-workflow-files-download-table';

    // table related
    public columns: ITableColumn[] = [
        {
            title: '',
            selectionColumnTitle: 'Thumbnail',
            key: 'files',
            type: EColumnType.THUMBNAIL,
            format: (value) => PublicationItemModel.getThumbnailUrl(this.activeVariant?._id, value),
            clickable: true
        },
        {
            title: 'Filename',
            key: 'publicationItemId'
        },
        {
            title: 'Tags',
            key: 'tags'
        },
    ];

    public iconColumn: ITableColumn = {
        title: 'Selected',
        key: 'selected',
        type: EColumnType.ICON,
        iconClass: () => 'nucicon_check_round_fill',
        color: AppConstants.FIRST_BRAND_COLOR
    };
    public selectedColumns: ITableColumn[];

    public pageIndex: number;
    public pageSize: number;
    public total: number;
    public items: PublicationItemModel[] = [];
    public subscription: Subscription;
    public disableNextPage: boolean;
    private cursorArray: CursorArray;

    private onDestroySubject = new Subject<void>();

    constructor(private fullModalService: FullModalService,
                private publicationService: PublicationsService,
                private paginatorService: PaginatorService,
                userSettingsStorageService: UserSettingsStorageService) {
        super(userSettingsStorageService);
    }

    public ngOnInit(): void {
        this.paginatorService.setPageIndex(this.tableId, 1);

        this.paginatorService.getPagination(this.tableId)
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe((result: {pageIndex: number; pageSize: number}) => {
                if (result.pageIndex === 1 || result.pageSize !== this.pageSize) { // reset cursor when needed
                    if (this.cursorArray) {
                        this.cursorArray.reset(result.pageIndex);
                    } else {
                        this.cursorArray = new CursorArray(result.pageIndex);
                    }
                }
                this.pageIndex = result.pageIndex;
                this.pageSize = result.pageSize;
                this.getPublicationItems();
            });

        if (this.publication.workflow.sequentialNumbering) {
            this.columns.push({
                title: 'Page numbers',
                key: 'formattedPageNumbers',
            });
        }
        this.selectedColumns = this.columns.concat(this.iconColumn);
    }

    public ngOnDestroy(): void {
        this.onDestroySubject.next();
        this.onDestroySubject.complete();
    }

    public getPublicationItems(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }

        const cursor = this.cursorArray.getCursor(this.pageIndex);

        this.subscription = this.publicationService.getItemsForPublication(
            this.publication._id,
            this.step._id,
            this.activeFilters,
            this.pageSize,
            0,
            cursor)
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe(
                (result) => {
                    this.items = result.items;
                    this.disableNextPage = !result.hasNext;

                    const sortProperty = this.publication.workflow.sequentialNumbering ? 'firstPageNumber' : '_id';
                    if (this.items.length > 0) {
                        this.cursorArray.setCursor(this.pageIndex, sortProperty, this.items[this.items.length - 1]);
                    }
                },
                (error: ARApiError) => Toaster.handleApiError(error)
            );
    }
}
