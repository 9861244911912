import {Component, Inject, OnDestroy} from '@angular/core';
import {ReplaySubject, Subject} from 'rxjs';
import {PublicationItemModel} from '../../../../../../../models/api/publication-item.model';
import {NUC_POP_OUT_CONTENT_DATA, NucPopOutContentService} from '@relayter/rubber-duck';
import {DropdownItem} from '../../../../../../../models/ui/dropdown-item.model';
import {CampaignService} from '../../../../../../../api/services/campaigns.service';
import {ARApiError, ARLogger} from '@relayter/core';
import {Toaster} from '../../../../../../../classes/toaster.class';
import {takeUntil} from 'rxjs/operators';
import {CampaignItemModel} from '../../../../../../../models/api/campaign-item.model';
import {IDropdownItem} from '@relayter/rubber-duck/lib/interfaces/idropdown-item';
import {PublicationModel} from '../../../../../../../models/api/publication.model';

export interface ICustomWorkflowTableAssignActionData {
    publicationItemsSubject: ReplaySubject<PublicationItemModel[]>;
    campaignItem: CampaignItemModel;
    campaignId: string;
    publication: PublicationModel;
}

@Component({
    selector: 'standard-assign-table-action',
    templateUrl: './assign-table-action.component.html',
    styleUrls: ['./assign-table-action.component.scss']
})
export class AssignTableActionComponent implements OnDestroy {
    private onDestroySubject = new Subject<void>();
    private publicationItems: ReplaySubject<PublicationItemModel[]>;
    public data: IDropdownItem[] = [];
    private campaignItem: CampaignItemModel;
    private publication: PublicationModel;
    private campaignId: string;

    constructor(@Inject(NUC_POP_OUT_CONTENT_DATA) injectData: ICustomWorkflowTableAssignActionData,
                private popOutService: NucPopOutContentService,
                private campaignService: CampaignService) {
        this.publicationItems = injectData.publicationItemsSubject;
        this.campaignItem = injectData.campaignItem;
        this.campaignId = injectData.campaignId;
        this.publication = injectData.publication;
        this.getAllPublicationItems();
    }

    private getAllPublicationItems(): void {
        this.publicationItems
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe((items) => {
                this.data = items.map((item) => {
                    return new DropdownItem(item.getTitle(this.publication.workflow.sequentialNumbering), item._id);
                });
                if (this.data.length > 0) this.data.unshift(new DropdownItem('Unassign', ''));
            });
    }

    public optionClicked(publicationItemId: string): void {
        this.campaignService.assignCampaignItemToPublicationItem(this.campaignId, this.campaignItem._id, this.publication._id, publicationItemId)
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe({
                next: () => {
                    ARLogger.debug(`campaignItem: ${this.campaignItem._id} successfully assigned to publicationItem: ${publicationItemId}`);
                    this.popOutService.close(true);
                    },
                error: (error: ARApiError) => {
                    Toaster.handleApiError(error);
                    this.popOutService.close(false);
                }
        });

    }

    public ngOnDestroy(): void {
        this.onDestroySubject.next();
        this.onDestroySubject.complete();
    }

}
