import {ApiConstants} from '../api.constant';
import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {Observable} from 'rxjs';
import {ARPagedResponseDataModel} from '@relayter/core';
import {ApiPagedRequest} from '../requests/api-paged.request';
import {ETransitionStatus, TransitionItemDataModel} from '../../models/api/transition-item.model';
import {PublicationModel} from '../../models/api/publication.model';
import {TableSortOptions} from '../table-sort-options';
import {NewCursor} from '../new-api-cursor';
import {PublicationItemModel} from '../../models/api/publication-item.model';
import {CustomWorkflowFilterModel} from '../../models/api/custom-workflow-filter.model';
import {CustomWorkflowFilterOptionModel} from '../../models/api/custom-workflow-filter-option.model';
import {AppConstants} from '../../app.constants';
import {PublicationsService} from './publications.service';

@Injectable({
    providedIn: 'root'
})
export class PublicationsApiService extends ApiService<PublicationModel> {
    constructor() {
        super([ApiConstants.API_GROUP_PUBLICATIONS], PublicationModel);
    }

    // TODO: Add api calls from publications.service.ts
    public getTransitionItemsData(publication: string,
                                  recipeTasks: string[],
                                  cursor: NewCursor,
                                  limit?: number,
                                  offset?: number,
                                  tableSortOptions?: TableSortOptions): Observable<ARPagedResponseDataModel<TransitionItemDataModel>> {
        const request = new ApiPagedRequest([
            ...this.groups,
            publication,
            ApiConstants.API_METHOD_TRANSITION_ITEMS
        ], TransitionItemDataModel, limit, offset, tableSortOptions, cursor);
        request.additionalQueryParams
            .addParam('recipeTasks', recipeTasks)
            .addParam('status', ETransitionStatus.DONE);
        return this.apiRequestService.find(request);
    }

    public getPublicationItemsForPackageInfo(publicationId: string,
                                             campaignPackageId: string,
                                             workflowStepId: string,
                                             filterMap?: Map<CustomWorkflowFilterModel, CustomWorkflowFilterOptionModel[]>,
                                             limit = AppConstants.PAGE_SIZE_MAX,
                                             offset = 0,
                                             cursor?: NewCursor,
                                             tableSortOptions?: TableSortOptions): Observable<ARPagedResponseDataModel<PublicationItemModel>> {

        const request = new ApiPagedRequest([
            ...this.groups,
            publicationId,
            ApiConstants.API_METHOD_ITEMS,
            ApiConstants.API_METHOD_PACKAGE_INFO
        ], PublicationItemModel, limit, offset, tableSortOptions, cursor);

        request.additionalQueryParams
            .addParam('campaignPackageId', campaignPackageId)
            .addParam('workflowStepId', workflowStepId);

        PublicationsService.addFiltersToParams(request.additionalQueryParams, filterMap);
        return this.apiRequestService.find(request);
    }
}
