import {autoserialize, autoserializeAs} from 'cerialize';

export class AfterEffectsProjectFileUsageInTemplateModel {
    @autoserialize public _id: string;
    @autoserialize public name: string;
}

export class AfterEffectsProjectFileUsageInRulesetModel {
    @autoserialize public _id: string;
    @autoserialize public name: string;
}

export class AfterEffectsProjectFileUsageModel {
    @autoserializeAs(AfterEffectsProjectFileUsageInTemplateModel) public templates:
        AfterEffectsProjectFileUsageInTemplateModel[];
    @autoserializeAs(AfterEffectsProjectFileUsageInRulesetModel) public rulesets:
        AfterEffectsProjectFileUsageInTemplateModel[];
}
