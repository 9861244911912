<div class="container" #container>

    <div class="sticky-notes-canvas" cdkDrag (cdkDragStarted)="startDragging()"
         [class.horizontal-scale]="horizontal()"
         [cdkDragStartDelay]="1" [class.disable-zoom-transition]="disableZoomTransition"
         (cdkDragReleased)="stopDragging()"
         [hidden]="loadingImage">
        <div class="images-container" #imagesContainer cdkDragHandle>
            <ng-container *ngFor="let item of items(); let index = index">
                <ng-container *ngIf="item.previewImage; else placeHolder">
                    <div class="image">
                        <img #image
                             [src]="item.previewImage"
                             [class.horizontal-scale]="horizontal"
                             (load)="fitToContainer(); loadingImage = false"/>
                        <rl-loading-indicator [hide]="!loadingImage" [contain]="true"></rl-loading-indicator>
                        <sticky-note-overlay [item]="item" [notes]="stickyNotes" [isDragging]="dragging()"
                                                       (createStickyNote)="newStickyNote($event)"
                                                       [overlay]="overlay()"
                        ></sticky-note-overlay>
                    </div>
                </ng-container>
            </ng-container>
        </div>
    </div>
</div>

<ng-template #placeHolder>
    <div class="image">
        <img #image
             (load)="fitToContainer(); loadingImage = false;"
             [class.horizontal-scale]="horizontal"
             [src]="placeHolderImage()">
        <nuc-empty-state-component
            class="empty-state"
            [small]="true"
            title="No preview available. Please upload or (re)generate files for this publication item."
            imageUrl="/assets/images/general_empty_state.svg">
        </nuc-empty-state-component>
    </div>
</ng-template>
