<form [formGroup]="formGroup">
    <nuc-form-field label="Publication item id">
        <nuc-input formControlName="name"></nuc-input>
    </nuc-form-field>

    <nuc-form-field label="Tags">
        <nuc-suggestive-input
            formControlName="tags"
            (searchTermChange)="onTagChanged($event)"
            [options]="tagOptions"
            placeholder="Add tags">
        </nuc-suggestive-input>
    </nuc-form-field>

    <form [formGroup]="formGroup.controls.templateFilters">
        <nuc-form-field label="Template type">
            <nuc-dropdown [items]="templateTypeOptions"
                          formControlName="templateType"
                          placeholder="Select template type">
            </nuc-dropdown>
        </nuc-form-field>
    </form>

    <nuc-form-field label="Template">
        <nuc-dropdown [items]="templates"
                      formControlName="template"
                      placeholder="Select template"
                      (requestData)="getTemplates()"
                      [total]="totalTemplates">
        </nuc-dropdown>
    </nuc-form-field>
</form>
