import {autoserialize, autoserializeAs} from 'cerialize';
import {
    CustomWorkflowActionModel,
    EBriefingActionName,
    EDownloadActionName,
    EItemActionName,
    EStickyNoteActionName,
    ETransitionTriggerActionName,
    EWorkflowActionName,
    EWorkflowConfigurationActionType,
    IActionName
} from './custom-workflow-action.model';
import {CustomWorkflowOptionModel, EWorkflowComponentOptionName} from './custom-workflow-option.model';

export class CustomWorkflowComponentModel {
    @autoserialize public _id: string;
    @autoserialize public name: string;
    @autoserialize public componentType: ECustomWorkflowComponentType;
    @autoserializeAs(CustomWorkflowActionModel) public actions: CustomWorkflowActionModel[];
    @autoserializeAs(CustomWorkflowOptionModel) public options: CustomWorkflowOptionModel[];
}

export enum ECustomWorkflowComponentType {
    ADD_PAGES = 'ADD_PAGES',
    AUTOMATION = 'AUTOMATION',
    PACKAGE_MANAGEMENT = 'PACKAGE_MANAGEMENT',
    PREVIEW = 'PREVIEW',
    FILES = 'FILES',
    STICKY_LIST = 'STICKY_LIST',
    STICKY_LOG = 'STICKY_LOG',
    LAYOUT = 'LAYOUT',
    OVERVIEW = 'OVERVIEW',
    STEP_OVERVIEW = 'STEP_OVERVIEW',
    LIST_VIEW = 'LIST_VIEW',
    BRIEFING = 'BRIEFING',
    UPLOAD_ASSETS = 'UPLOAD_ASSETS',
    UPLOAD = 'UPLOAD'
}

export enum EComponentTypeContext {
    STEP = 'STEP',
    WORKFLOW = 'WORKFLOW'
}

export enum EColumnConfigModel { // align with backend
    PUBLICATION_ITEM = 'PublicationItem',
    STICKY_NOTE = 'StickyNote'
}

export interface IAllowedActionConfig {
    actionType: EWorkflowConfigurationActionType;
    names: IActionName[];
}

export interface IComponentOptionConfig {
    name: EWorkflowComponentOptionName;
    model?: EColumnConfigModel
}

export interface IComponentTypeConfig {
    type: ECustomWorkflowComponentType;
    context: EComponentTypeContext;
    allowedActionsConfig: IAllowedActionConfig[]
    canBeReplacedBy?: ECustomWorkflowComponentType
    optionsConfig?: IComponentOptionConfig[];
}

export interface IColumnConfigOptionValueEntry {
    displayName: string;
    property: string
}

export type IComponentOptionValue = IColumnConfigOptionValueEntry[];

export const CUSTOM_WORKFLOW_COMPONENT_TYPES_CONFIGS: IComponentTypeConfig[] =
    [
        {
            type: ECustomWorkflowComponentType.OVERVIEW,
            context: EComponentTypeContext.WORKFLOW,
            allowedActionsConfig: [
                {actionType: EWorkflowConfigurationActionType.GENERAL_ACTION, names: [EWorkflowActionName.ARRANGE_PAGES]},
                {actionType: EWorkflowConfigurationActionType.ITEM_ACTION, names: Object.values(EItemActionName)},
                {actionType: EWorkflowConfigurationActionType.TRANSITION_TRIGGER, names: [ETransitionTriggerActionName.TRANSITION_TRIGGER]}
            ]
        },
        {
            type: ECustomWorkflowComponentType.STICKY_LOG,
            context: EComponentTypeContext.WORKFLOW,
            allowedActionsConfig: [
                {actionType: EWorkflowConfigurationActionType.GENERAL_ACTION, names: Object.values(EStickyNoteActionName)}
            ],
            optionsConfig: [
                {
                    name: EWorkflowComponentOptionName.COLUMN_CONFIG, model: EColumnConfigModel.STICKY_NOTE
                }
            ]
        },
        {
            type: ECustomWorkflowComponentType.BRIEFING,
            context: EComponentTypeContext.STEP,
            allowedActionsConfig: [
                {actionType: EWorkflowConfigurationActionType.BRIEFING_ACTION, names: Object.values(EBriefingActionName)},
                {actionType: EWorkflowConfigurationActionType.GENERAL_ACTION, names: [EWorkflowActionName.INLINE_EDITING]}
            ]
        },
        {
            type: ECustomWorkflowComponentType.PREVIEW,
            context: EComponentTypeContext.STEP,
            allowedActionsConfig: [
                {
                    actionType: EWorkflowConfigurationActionType.GENERAL_ACTION, names: (Object.values(EStickyNoteActionName) as IActionName[])
                        .concat([
                            EWorkflowActionName.SHOW_BRIEFING_CHANGES,
                            EWorkflowActionName.SHOW_LINKED_BRIEFING_ITEM,
                            EWorkflowActionName.EDIT_CONTENT_BRIEFING_ITEM
                        ])
                },
                {
                    actionType: EWorkflowConfigurationActionType.TRANSITION_TRIGGER, names: [
                        ETransitionTriggerActionName.TRANSITION_TRIGGER,
                        ETransitionTriggerActionName.UPLOAD_ITEM_FILES
                    ]
                },
                {actionType: EWorkflowConfigurationActionType.ITEM_ACTION, names: Object.values(EItemActionName)},
            ]
        },
        {
            type: ECustomWorkflowComponentType.FILES,
            context: EComponentTypeContext.STEP,
            allowedActionsConfig: [
                // all the download actions and upload
                {actionType: EWorkflowConfigurationActionType.GENERAL_ACTION, names: Object.values(EDownloadActionName)},
                {actionType: EWorkflowConfigurationActionType.TRANSITION_TRIGGER, names: [ETransitionTriggerActionName.UPLOAD_ITEM_FILES]},
            ]
        },
        {
            type: ECustomWorkflowComponentType.STICKY_LIST,
            context: EComponentTypeContext.STEP,
            allowedActionsConfig: [
                {actionType: EWorkflowConfigurationActionType.GENERAL_ACTION, names: Object.values(EStickyNoteActionName)}
            ],
            optionsConfig: [
                {
                    name: EWorkflowComponentOptionName.COLUMN_CONFIG, model: EColumnConfigModel.STICKY_NOTE
                }
            ]
        },
        {
            type: ECustomWorkflowComponentType.ADD_PAGES,
            context: EComponentTypeContext.STEP,
            allowedActionsConfig: [
                {actionType: EWorkflowConfigurationActionType.TRANSITION_TRIGGER, names: [ETransitionTriggerActionName.ADD_PAGES]}
            ]
        },
        {
            type: ECustomWorkflowComponentType.LAYOUT,
            context: EComponentTypeContext.STEP,
            allowedActionsConfig: [
                {
                    actionType: EWorkflowConfigurationActionType.GENERAL_ACTION, names: [
                        EWorkflowActionName.EDIT_LAYOUT,
                        EWorkflowActionName.SHOW_LAYOUT_REVISIONS,
                        EWorkflowActionName.EDIT_LAYOUT_BRIEFING_ITEM
                    ]
                },
                {actionType: EWorkflowConfigurationActionType.ITEM_ACTION, names: Object.values(EItemActionName)},
                {actionType: EWorkflowConfigurationActionType.TRANSITION_TRIGGER, names: [ETransitionTriggerActionName.TRANSITION_TRIGGER]}
            ]
        },
        {
            type: ECustomWorkflowComponentType.STEP_OVERVIEW,
            context: EComponentTypeContext.STEP,
            allowedActionsConfig: [
                {actionType: EWorkflowConfigurationActionType.ITEM_ACTION, names: Object.values(EItemActionName)},
                {actionType: EWorkflowConfigurationActionType.TRANSITION_TRIGGER, names: [ETransitionTriggerActionName.TRANSITION_TRIGGER]},
            ]
        },
        {
            type: ECustomWorkflowComponentType.LIST_VIEW,
            context: EComponentTypeContext.STEP,
            allowedActionsConfig: [
                {actionType: EWorkflowConfigurationActionType.ITEM_ACTION, names: Object.values(EItemActionName)},
                {actionType: EWorkflowConfigurationActionType.TRANSITION_TRIGGER, names: [ETransitionTriggerActionName.TRANSITION_TRIGGER]}
            ],
            optionsConfig: [
                {
                    name: EWorkflowComponentOptionName.COLUMN_CONFIG, model: EColumnConfigModel.PUBLICATION_ITEM
                }
            ]
        },
        {
            type: ECustomWorkflowComponentType.AUTOMATION,
            context: EComponentTypeContext.STEP,
            allowedActionsConfig: [
                // we don't check the name of the transition for this component
                {actionType: EWorkflowConfigurationActionType.TRANSITION_TRIGGER, names: [ETransitionTriggerActionName.TRANSITION_TRIGGER]}
            ]
        },
        {
            type: ECustomWorkflowComponentType.PACKAGE_MANAGEMENT,
            context: EComponentTypeContext.STEP,
            allowedActionsConfig: [
                {
                    actionType: EWorkflowConfigurationActionType.GENERAL_ACTION, names: [
                        EWorkflowActionName.ADD_TO_PACKAGE,
                        EWorkflowActionName.REMOVE_FROM_PACKAGE,
                        EWorkflowActionName.ADD_OR_EDIT_PACKAGE
                    ]
                },
                {actionType: EWorkflowConfigurationActionType.TRANSITION_TRIGGER, names: [ETransitionTriggerActionName.TRANSITION_TRIGGER]},
                {actionType: EWorkflowConfigurationActionType.ITEM_ACTION, names: Object.values(EItemActionName)},
            ]
        },
        {
            type: ECustomWorkflowComponentType.UPLOAD_ASSETS,
            context: EComponentTypeContext.STEP,
            allowedActionsConfig: [
                {actionType: EWorkflowConfigurationActionType.TRANSITION_TRIGGER, names: [ETransitionTriggerActionName.UPLOAD_ASSETS]}
            ]
        },
        {
            type: ECustomWorkflowComponentType.UPLOAD,
            context: EComponentTypeContext.STEP,
            allowedActionsConfig: [
                {actionType: EWorkflowConfigurationActionType.TRANSITION_TRIGGER, names: [ETransitionTriggerActionName.UPLOAD_ITEM_FILES]}
            ]
        }
    ];
