<div class="upload-box">
    <div class="upload-header">
        <div class="upload-title">
            <span class="title black body-strong">{{fileUploadTitle}}</span>
            @if (subTitle) { <span class="title">{{subTitle}}</span>}
        </div>
        <i class="nucicon_check upload-check" [ngClass]="{'active': active}"></i>
    </div>
    <div class="file-input">
        <rl-reactive-file-input
            inactive-title="Drag your {{dragTitle}} here"
            [multiple]="multiple"
            (filesChanged)="onFileChanged($event)">
        </rl-reactive-file-input>
    </div>
</div>
