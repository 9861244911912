/**
 *  error.constants
 *  app-relayter-web
 *  Created by nandamhuis on 11/07/2017.
 *  Copyright © 2017 Creative Media Network. All rights reserved.
 */
import {Injectable} from '@angular/core';

@Injectable()
export class ErrorConstants {
    public static readonly INTERNAL_SERVER_ERROR_MESSAGE = 'Something went wrong';

    public static readonly API_ERROR_CODES = {
        BAD_REQUEST_FILE_TYPE: 1000,
        BAD_REQUEST_EXPORT_TYPE_NOT_ALLOWED: 1003,
        BAD_REQUEST_TAG: 1005,
        BAD_REQUEST_EAN: 1006,
        BAD_REQUEST_MALLFORMED: 1007,
        BAD_REQUEST_CAMPAIGN_DATE: 1008,
        BAD_REQUEST_ROLE: 1009,
        BAD_REQUEST_CAMPAIGN: 1010,
        BAD_REQUEST_USER: 1011,
        BAD_REQUEST_EMAIL: 1012,
        BAD_REQUEST_TEAM: 1013,
        BAD_REQUEST_PRODUCT_IN_USE: 1015,
        BAD_REQUEST_INVALID_OFFER: 1016,
        BAD_REQUEST_INVALID_STATUS: 1017,
        BAD_REQUEST_INVALID_STATUS_TRANSITION: 1018,
        BAD_REQUEST_UNEVEN_NUMBER_OF_PAGES: 1019,
        BAD_REQUEST_INDESIGN_JOB_ALREADY_IN_PROGRESS: 1020,
        BAD_REQUEST_INVALID_IDML_AND_PDF: 1021,
        BAD_REQUEST_CAMPAIGN_ITEM_NOT_INSIDE_GROUPED_AREAS: 1041,
        BAD_REQUEST_DUPLICATE_LIBRARY_NAME: 1104,
        BAD_REQUEST_DUPLICATE_PUBLICATIONS_NOT_ALLOWED: 1064,
        BAD_REQUEST_DUPLICATE_CAMPAIGNS_NOT_ALLOWED: 1065,
        BAD_REQUEST_DUPLICATE_DATA_FIELD_NOT_ALLOWED: 1080,
        BAD_REQUEST_PACKAGE_SETUP_VALIDATION_FAILED: 1159,
        BAD_REQUEST_DATATYPE_VALUE_STRING_REQUIRED: 1094, // TODO renumber
        BAD_REQUEST_INVALID_JOB_DATA: 1097,
        BAD_REQUEST_DUPLICATE_DATA_FIELD_NAME_NOT_ALLOWED: 1161,
        BAD_REQUEST_DUPLICATE_PACKAGE_RULE_NAME_NOT_ALLOWED: 1096,
        BAD_REQUEST_PACKAGE_SETUP_RULE_VALIDATION_FAILED: 1162,
        BAD_REQUEST_DUPLICATE_PACKAGE_SETUP_NOT_ALLOWED: 1163,
        BAD_REQUEST_FILE_NOT_FOUND_S3: 1102,
        BAD_REQUEST_PACKAGE_SETUP_IN_USE: 1115,
        BAD_REQUEST_INVALID_PAGE_SIZE_FOR_TEMPLATE: 1118,
        BAD_REQUEST_DUPLICATE_WORKFLOW_AUTOMATION_NOT_ALLOWED: 1124,
        BAD_REQUEST_INDESIGN_LIBRARY_WITH_RULE_SET_IN_USE: 1121,
        BAD_REQUEST_DUPLICATE_SIGN_OFF_USER: 1178,
        BAD_REQUEST_INDESIGN_RULE_SET_ASSET_ITEM_WIDTH_REQUIRED: 1179,
        BAD_REQUEST_INDESIGN_RULE_SET_ASSET_ITEM_HEIGHT_REQUIRED: 1130,
        BAD_REQUEST_DUPLICATE_FORMAT_RULESET_NOT_ALLOWED: 1148,
        BAD_REQUEST_DUPLICATE_PERMISSIONS_NOT_ALLOWED: 1149,
        BAD_REQUEST_DUPLICATE_ROLES_NOT_ALLOWED: 1150,
        BAD_REQUEST_INVALID_INDESIGN_RULE_SET_ITEM_RULE_CONDITION: 1151,
        BAD_REQUEST_INVALID_WORKFLOW_AUTOMATION_CONDITION: 1152,
        BAD_REQUEST_INVALID_PACKAGE_SETUP_CONDITION: 1153,
        BAD_REQUEST_DATA_FIELD_OUT_OF_RANGE: 1131,
        BAD_REQUEST_CAMPAIGN_ITEM_ALREADY_ASSIGNED: 1138,
        BAD_REQUEST_USER_ALREADY_IN_TEAM: 1140,
        BAD_REQUEST_USER_ALREADY_IN_OTHER_TEAM: 1141,
        BAD_REQUEST_DUPLICATE_VARIANTS_NOT_ALLOWED: 1143,
        BAD_REQUEST_INVALID_INDESIGN_RULE_SET_ITEM_RULE_VALUE: 1156,
        BAD_REQUEST_BRIEFING_EXPORT_NO_VARIANT_KEY: 1180,
        BAD_REQUEST_BRIEFING_EXPORT_NO_VARIANTS_ENABLED: 1181,
        BAD_REQUEST_INVALID_LIBRARY_ITEMS_FOR_TEMPLATE: 1182,
        BAD_REQUEST_INVALID_PACKAGE_TYPE: 1183,
        BAD_REQUEST_INVALID_PACKAGE_EXPORT_FORMAT: 1184,
        BAD_REQUEST_NO_PACKAGES_TO_EXPORT: 1185,
        BAD_REQUEST_VARIANT_NOT_ALLOWED: 1186,
        BAD_REQUEST_DUPLICATE_ASSET_NOT_ALLOWED: 1202,
        BAD_REQUEST_DUPLICATE_TEMPLATE_NOT_ALLOWED: 1203,
        BAD_REQUEST_INVALID_STICKY_NOTE_ACTION: 1205,
        BAD_REQUEST_TRANSITION_IN_USE: 1226,
        FORBIDDEN_DELETE_SELF: 2001,
        FORBIDDEN_DELETE_OWNER: 2002,
        FORBIDDEN_EDIT_ROLE: 2003,
        FORBIDDEN_LIMIT_OF_GENERAL: 2004,
        FORBIDDEN_LIMIT_OF_PLAN_ASSETS: 2005,
        FORBIDDEN_LIMIT_OF_PLAN_USERS: 2006,
        FORBIDDEN_ROLE_NOT_EMPTY: 2007,
        FORBIDDEN_CONFIG: 2008,
        FORBIDDEN_INVALID_IDML: 2009,
        FORBIDDEN_DUPLICATE_KEY: 2010,
        INVALID_STICKY_NOTE_STATUS: 2011,
        FORBIDDEN_NOT_ALLOWED_TO_DOWNLOAD: 2016,
        FORBIDDEN_NOT_ALLOWED_TO_DELETE_CAMPAIGN_ITEM_IN_USE: 2019,
        USER_LIMIT_REACHED_ERROR: 2027,
        COULD_NOT_SAVE: 3001,
        COULD_NOT_UPDATE: 3002,
        COULD_NOT_DELETE: 3003,
        COULD_NOT_READ: 3004,
        NOT_FOUND_EXPORT_SIZE: 4001,
        NOT_FOUND_CAMPAIGN: 4002,
        NOT_FOUND_PRODUCT: 4003,
        NOT_FOUND_ROLE: 4004,
        NOT_FOUND_PUBLICATION: 4005,
        NOT_FOUND_PLAN: 4006,
        NOT_FOUND_INVITE: 4007,
        NOT_FOUND_TEAM: 4008,
        NOT_FOUND_TAGS: 4009,
        NOT_FOUND_RESETPASSWORD: 4010,
        NOT_FOUND_BILLING: 4011,
        NOT_FOUND_USER: 4013,
        NOT_FOUND_PRODUCTS: 4014,
        NOT_FOUND_OFFER_TYPE: 4015,
        NOT_FOUND_CAMPAIGN_ITEM: 4017,
        NOT_FOUND_MENU_ITEMS: 4018,
        NOT_FOUND_ASSETS: 4020,
        NOT_FOUND_NOTIFICATION_MESSAGE: 4021,
        NOT_FOUND_PERMISSIONS: 4023,
        NOT_FOUND_TEMPLATES: 4024,
        NOT_FOUND_PAGE: 4026,
        NOT_FOUND_ASSET_FORMAT: 4029,
        NOT_FOUND_ASSET_IDENTIFY: 4030,
        NOT_FOUND_BRIEFING: 4048,
        NOT_FOUND_WORKFLOW: 4073,
        NOT_FOUND_PUBLICATION_ITEM: 4082,
        NOT_FOUND_PACKAGE_SETUP: 4091,
        NOT_FOUND_WORKFLOW_AUTOMATION: 4102,
        NOT_FOUND_WORKFLOW_AUTOMATION_RULE: 4103,
        NOT_FOUND_VARIANT: 4109,
        NOT_FOUND_EXPORT_NOTES: 4110,
        NOT_FOUND_AUTHORIZATION_CODE: 4120,
        NOT_FOUND_DEFAULT: 4999,
        WRONG_CREDENTIALS: 5000,
        UNAUTHORIZED_WRONG_CREDENTIALS: 5001,
        INVALID_LOGIN_TOKEN: 5002,
        SESSION_REVOKED: 5005,
        INVALID_REFRESH_TOKEN: 5015,
        TOKEN_EXPIRED: 5016,
        UNAUTHORIZED_INVALID_ITEM: 5014,
        SERVICE_UNAVAILABLE: 503,
        NOT_FOUND_DEVICE_CODE: 4119
    };
}
