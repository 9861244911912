<briefing-table [campaignId]="campaign?._id"
                [publicationId]="publication?._id"
                [additionalColumns]="additionalColumns"
                [actions]="briefingActions"
                [briefingTableOptions]="briefingTableOptions"
                [tableId]="tableId"
                [selectionMode]="selectionMode"
                [assignableItems]="assignableItems"
                [selection]="selection"
                (actionClicked)="handleTableRowAction($event)">
    <ng-container *ngIf="selection.hasValue()" multi-actions>
        <div class="popout-button">
            <nuc-button-secondary
                text="{{selection.selected.length}} selected {{selection.selected.length === 1 ? 'item' : 'items'}}"
                icon="nucicon_dots_vertical"
                iconPosition="end"
                [matMenuTriggerFor]="menu">
            </nuc-button-secondary>
            <mat-menu #menu="matMenu" class="menu-items-container">
                <button *ngFor="let action of multiCompatibleActions"
                        mat-menu-item
                        (click)="handleMultiSelectionAction(action)"
                        [disableRipple]="true">{{ action.title }}
                </button>
            </mat-menu>
        </div>

        <div class="vertical-line"></div>
    </ng-container>
</briefing-table>
