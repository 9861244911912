<ng-container *ngIf="publicationItemSubscription?.closed; else loadingIndicator">
    <rl-reactive-file-input class="file-input"
                            (filesChanged)="onFilesChanged($event)"
                            inactive-title="Drag your IDML, PNG/PDF and or asset files here"></rl-reactive-file-input>

    <div class="uploads">
        <ng-container *ngFor="let uploadGroup of uploadGroups$ | async" [ngSwitch]="uploadGroup.viewType">
            <ng-container *ngSwitchCase="EUploadCategory.TEMPLATE">
                <div class="upload-template-item">
                    <div class="top-container">
                        <ng-container *ngTemplateOutlet="matchedPublicationItem; context: uploadGroup"></ng-container>

                        <nuc-button-secondary icon="nucicon_trash_fill"
                                              (click)="onDeleteButtonClicked(uploadGroup)"></nuc-button-secondary>
                    </div>

                    <div class="files">
                        <div class="file" *ngFor="let fileTypeCategory of uploadGroup.requiredFileExtensions"
                             [class.inactive]="!getUploadForFileType(uploadGroup.uploads, fileTypeCategory)">
                            <img class="file-type-image" [src]="fileTypeCategory | fileTypeIcon"/>
                            <div class="file-info progress">
                                <p>{{fileTypeCategory | uppercase}}</p>
                                <ng-container
                                    *ngIf="getUploadForFileType(uploadGroup.uploads, fileTypeCategory) as upload; else placeholderText">
                                    <ng-container
                                        *ngIf="{value: upload.progress$ | async} as progress; else placeholderText">
                                        <img class="status-icon" *ngIf="progress.value === EUploadStatus.Done"
                                             src="/assets/images/icon_success.svg"/>
                                        <img class="status-icon" *ngIf="progress.value === EUploadStatus.Failed"
                                             src="/assets/images/icon_failed.svg"/>
                                        <nuc-progress-bar class="progress-bar" [value]="progress.value"
                                                          *ngIf="progress.value !== EUploadStatus.Done && progress.value !== EUploadStatus.Failed"></nuc-progress-bar>
                                    </ng-container>
                                </ng-container>
                                <ng-template #placeholderText>
                                    <p>Not uploaded</p>
                                </ng-template>
                            </div>
                        </div>

                    </div>
                </div>
            </ng-container>
            <ng-container *ngSwitchCase="EUploadCategory.FILE">
                <div class="display-asset-item">
                    <file-upload-card-view *ngFor="let uploadFile of uploadGroup.uploads"
                                           class="item"
                                           [itemWidth]="200"
                                           [uploadFile]="uploadFile"
                                           (deleteAssetClicked)="onDeleteButtonClicked(uploadGroup)">
                        <div file-status>
                            <ng-container
                                *ngTemplateOutlet="matchedPublicationItem; context: uploadGroup"></ng-container>
                        </div>
                    </file-upload-card-view>
                </div>
            </ng-container>
        </ng-container>
    </div>

</ng-container>

<ng-template #loadingIndicator>
    <rl-loading-indicator size="large"></rl-loading-indicator>
</ng-template>

<ng-template #matchedPublicationItem let-publicationItem$="publicationItem$" let-variant="variant"
             let-validationStatus$="validationStatus$" let-identifier="identifier">
    <div>
        <div class="title-container">
            <ng-container *ngIf="publicationItem$ | async as publicationItem">
                <p>{{(publicationItem | publicationItemDisplay:EPublicationDisplayProperties.UPLOAD_DISPLAY_NAME:undefined:modalData.publication.workflow.sequentialNumbering) || 'Item not found'}}</p>
                <ng-container *ngIf="variant">
                    <p>/</p>
                    <p>{{variant.getTitle()}}</p>
                </ng-container>
            </ng-container>

            <ng-container *ngIf="validationStatus$ | async as validationStatus">
                <i class="status-icon green nucicon_check_round_fill"
                   *ngIf="validationStatus === VALIDATION_STATUS.VALID"></i>
                <span *ngIf="validationStatus === VALIDATION_STATUS.INVALID" class="text-ellipsis red">
                    No matching items
                    <i class="status-icon red nucicon_close_round_fill"></i>
                </span>
            </ng-container>
        </div>

        <ng-container *ngIf="validationStatus$ | async as validationStatus">
            <p *ngIf="validationStatus === VALIDATION_STATUS.VALID">{{identifier}}</p>
        </ng-container>
    </div>
</ng-template>
