import {Component, DestroyRef, ElementRef, inject, viewChild} from '@angular/core';
import {FileTypeUtil} from '../../../../../../classes/file-type.util';
import {CustomWorkflowBaseComponent} from '../custom-workflow-base.component';
import {PublicationsService} from '../../../../../../api/services/publications.service';
import {UserIsAllowedToPipe} from '../../../../../../pipes/user-is-allowed-to.pipe';
import {CustomWorkflowService} from '../custom-workflow.service';
import {BUTTON_TYPE, FullModalService, NucDialogConfigModel, NucDialogService} from '@relayter/rubber-duck';
import {MonitoredTransitionsService} from '../../../../../../api/services/monitored-transitions.service';
import {Toaster} from '../../../../../../classes/toaster.class';
import {EUploadCategory, UploadGroupModel} from '../custom-workflow-files/custom-workflow-upload/upload-group.model';
import {combineLatest, forkJoin, Observable, of, ReplaySubject, Subscription} from 'rxjs';
import {map, shareReplay, startWith, switchMap, take, withLatestFrom} from 'rxjs/operators';
import {EUploadStatus, UploadModel} from '../../../../../../components/upload-file-component/upload.model';
import {ARLogger} from '@relayter/core';
import {PublicationItemModel} from '../../../../../../models/api/publication-item.model';
import {RLValidatorRegExConstants} from '../../../../../../classes/validators/rl-validator-regex.constant';
import {VariantModel} from '../../../../../../models/api/variant.model';
import {AmazonService} from '../../../../../../api/services/amazon.service';
import {EPublicationDisplayProperties} from '../../../../../../pipes/publication-item-display.pipe';
import {KeyItemMapModel} from '../custom-workflow-files/custom-workflow-upload/custom-workflow-files-upload.component';
import {CustomWorkflowActionModel, ETransitionTriggerActionName} from '../../../../../../models/api/custom-workflow-action.model';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';

@Component({
  selector: 'custom-workflow-upload-component',
  templateUrl: './custom-workflow-upload.component.html',
  styleUrls: ['./custom-workflow-upload.component.scss']
})
export class CustomWorkflowUploadComponent extends CustomWorkflowBaseComponent {
    private destroyRef = inject(DestroyRef);
    protected amazonService: AmazonService = inject(AmazonService);
    public readonly VALIDATION_STATUS = UploadGroupModel.VALIDATION_STATUS;
    public readonly EUploadCategory = EUploadCategory;
    protected readonly EPublicationDisplayProperties = EPublicationDisplayProperties;
    protected readonly EUploadStatus = EUploadStatus;
    public publicationItemsSubscription: Subscription;

    public fileInput = viewChild('fileInput', {read: ElementRef});

    public saveButtonDisabled: boolean;
    public allowedFileExtensions = [
        ...[FileTypeUtil.EXTENSIONS.PDF,
            FileTypeUtil.EXTENSIONS.IDML,
            FileTypeUtil.EXTENSIONS.AEP],
        ...FileTypeUtil.FILE_TYPE_CATEGORIES.IMAGE.extensions,
        ...FileTypeUtil.FILE_TYPE_CATEGORIES.VIDEO.extensions
        ];
    public isDragging: boolean;

    private publicationVariants: VariantModel[];
    private publicationItems: PublicationItemModel[] = [];
    private uploadGroups: UploadGroupModel[] = [];
    public uploadGroups$: ReplaySubject<UploadGroupModel[]> = new ReplaySubject<UploadGroupModel[]>(1);

    public uploadsReady$: Observable<boolean> = this.uploadGroups$.pipe(
        switchMap((groups: UploadGroupModel[]) => {
            if (groups.length === 0 ||
                groups.some((group) => group.uploads.length !== group.requiredFileExtensions.length)) {
                return of(false);
            }

            const progressDone = groups
                .map((group) => group.uploads)
                .reduce((acc, uploads) => [...acc, ...uploads])
                .map((upload) => upload.progress$.pipe(map((progress) => progress === EUploadStatus.Done)));

            const itemsValid = groups.map((group) => group.validationStatus$.pipe(
                map((status) => status === this.VALIDATION_STATUS.VALID)));

            return combineLatest([...progressDone, ...itemsValid]).pipe(map((results) => results.every((result) => result)));
        }),
        startWith(false),
        shareReplay(1),
    );

    private publicationsService: PublicationsService = inject(PublicationsService);
    public uploadAction: CustomWorkflowActionModel;

    constructor(userIsAllowedToPipe: UserIsAllowedToPipe,
                customWorkflowService: CustomWorkflowService,
                publicationService: PublicationsService,
                dialogService: NucDialogService,
                fullModalService: FullModalService,
                monitoredTransitionsService: MonitoredTransitionsService){
        super(userIsAllowedToPipe, customWorkflowService, publicationService, dialogService, fullModalService, monitoredTransitionsService);

        this.customWorkflowService.publicationVariants$
            .pipe(take(1), takeUntilDestroyed(this.destroyRef))
            .subscribe(publicationVariants => this.publicationVariants = publicationVariants);

    }

    protected setupData(): void {
    }

    protected initComponent() {
        super.initComponent();

        this.getPublicationItems();
        this.uploadsReady$.pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((ready) => this.saveButtonDisabled = !ready);

        this.uploadAction = this.allowedActions.find((action) =>
            action.name === ETransitionTriggerActionName.UPLOAD_ITEM_FILES);
    }

    /**
     * Responds to file drops
     * @param {File[]} files
     */
    public onFilesChanged(files: File[]): void {
        if (files.length > 0) {
            const filteredFiles = files.filter(
                (file) => this.allowedFileExtensions.some(fileExtensionGroup =>
                    fileExtensionGroup.includes(FileTypeUtil.getExtensionFromFileName(file.name))));

            if (filteredFiles.length !== files.length) {
                Toaster.warn('One or more files did not have the correct filetype');
            }

            const newUploadGroups = this.groupAndStartUploads(filteredFiles);

            for (const newUploadGroup of newUploadGroups) {
                let uploadGroup = this.uploadGroups.find((foundGroup: UploadGroupModel) =>
                    foundGroup.identifier === newUploadGroup.identifier && foundGroup.variant?._id === newUploadGroup.variant?._id);

                if (!uploadGroup) { // uploadGroup is new
                    this.uploadGroups.push(newUploadGroup);
                    uploadGroup = newUploadGroup;
                } else { // uploadGroup exists, update with new uploads
                    const types = newUploadGroup.uploads.map((upload) => upload.fileType);
                    // Filter out new uploaded files
                    const filteredUploads = uploadGroup.uploads.filter((upload) => !types.includes(upload.fileType));

                    uploadGroup.uploads = [...filteredUploads, ...newUploadGroup.uploads];
                }

                // Maybe move to model itself
                uploadGroup.uploads = uploadGroup.uploads.filter(upload => uploadGroup.requiredFileExtensions.includes(upload.fileType));
            }
            this.uploadGroups$.next(this.uploadGroups);
        }
    }

    /**
     * Groups files together based on filename and starts the validation & uploads
     * @param {File[]} files
     * @returns {UploadGroupModel[]}
     */
    private groupAndStartUploads(files: File[]): UploadGroupModel[] {
        const uploadGroups = [];

        let invalidVariantsDetected = false;
        files.forEach((file) => {
            // Normalize the file name, because it could have another code page (selected files with the file system versus dnd)
            const uploadFile = file.name.normalize();
            const matchedPublicationItems = this.publicationItems.filter(
                (item) => {
                    const fileName = this.getPublicationItemFileName(item);
                    if (this.publication.workflow.sequentialNumbering) { // with page number
                        return uploadFile.includes(fileName);
                    } else {
                        if (this.publicationVariants?.length) {
                            const publicationItemVariant = this.publicationVariants.find(variant => {
                                const fileNameParts = [...variant.name.split(' '), fileName];
                                return FileTypeUtil.getFilenameWithoutExtension(uploadFile) === fileNameParts.join('_');
                            });

                            return !!publicationItemVariant;
                        } else {
                            return FileTypeUtil.getFilenameWithoutExtension(uploadFile) === fileName;
                        }
                    }
                });
            // TODO: Show dropdown for items that match multiple publication item ids
            if (matchedPublicationItems.length > 1) {
                ARLogger.warn(`Multiple publication items matched for file: ${file.name}`);
            }

            const matchedPublicationItem = matchedPublicationItems.length ? matchedPublicationItems[0] : null;
            const identifier = matchedPublicationItem ?
                matchedPublicationItem.publicationItemId : FileTypeUtil.getFilenameWithoutExtension(file.name);

            let fileVariant;
            if (matchedPublicationItem) {
                const pubItemFileName = this.getPublicationItemFileName(matchedPublicationItem);
                fileVariant = this.getUploadVariant(uploadFile, pubItemFileName);
                if ((!fileVariant && this.publicationVariants.length > 0) || (fileVariant && this.publicationVariants.length === 0)) {
                    invalidVariantsDetected = true;
                    return;
                }
            }

            let uploadGroup = uploadGroups.find((foundGroup) => foundGroup.identifier === identifier && foundGroup.variant?._id === fileVariant?._id);
            if (!uploadGroup) {
                uploadGroup = new UploadGroupModel(identifier, matchedPublicationItem, fileVariant);
                uploadGroups.push(uploadGroup);
            }

            uploadGroup.uploads.push(this.amazonService.createUpload(file));
        });

        if (invalidVariantsDetected) {
            Toaster.warn('One or more files did not belong to any variant of the publication');
        }
        return uploadGroups;
    }

    public onSaveButtonClicked(): void {
        // Map the upload groups to a list of s3Keys, the itemId and variantId (optional)
        const groupedS3Keys$: Observable<KeyItemMapModel>[] =
            this.uploadGroups.map((group) =>
                group.uploads.map((upload) => upload.s3Key$.pipe(
                    withLatestFrom(group.publicationItem$),
                    map(([s3Key, publicationItem]) => new KeyItemMapModel(s3Key, publicationItem._id, group.variant?._id)),
                    take(1))))
                .reduce((acc, items) => [...acc, ...items]);

        // POST the array of s3Keys
        forkJoin(groupedS3Keys$).pipe(
            switchMap((groupedS3Keys) => {
                const itemIds = groupedS3Keys
                    .map((grouped) => grouped.itemId)
                    .filter((itemId, index, array) => array.indexOf(itemId) === index);

                return this.publicationsService.postTransitionItem(this.publication._id, this.uploadAction.transition, {
                    itemIds,
                    uploads: groupedS3Keys
                });
            }),
            takeUntilDestroyed(this.destroyRef)
        ).subscribe({
            next: () => {
                Toaster.success('Items Uploaded, Transition Scheduled');
                this.clearUploadGroups();

            },
            error: (error) => {
                Toaster.handleApiError(error);
            }
        });
    }

    /**
     * Get publication file name
     */
    private getPublicationItemFileName(publicationItem: PublicationItemModel, variant?: VariantModel): string {
        const filePrefix = variant ? [...variant.name.split(' ')] : [];
        if (this.publication.workflow.sequentialNumbering) {
            // For add the right indesign file
            filePrefix.push(publicationItem.numberOfPages === 1 ?
                `${publicationItem.firstPageNumber}` :
                `${publicationItem.firstPageNumber}-${publicationItem.firstPageNumber + publicationItem.numberOfPages - 1}`);
        }
        return filePrefix.length > 0 ? [...filePrefix, publicationItem.publicationItemId].join('_')
            .replace(RLValidatorRegExConstants.PUBLICATION_ITEM_FILE_NAME, '') : publicationItem.publicationItemId;
    }

    /**
     * Get variant from the uploaded files
     */
    private getUploadVariant(uploadFileName, pubItemFileName): VariantModel {
        const fileName = FileTypeUtil.getFilenameWithoutExtension(uploadFileName);
        return this.publicationVariants.find((variant) => {
            const variantName = variant.name.replace(RLValidatorRegExConstants.PUBLICATION_ITEM_FILE_NAME, '').replace(/ /g, '_');
            const expectedPubItemFileName = `${variantName}_${pubItemFileName}`;

            return fileName.includes(expectedPubItemFileName);
        });
    }

    public getPublicationItems(): void {
        this.publicationItemsSubscription?.unsubscribe();

        this.publicationItemsSubscription = this.publicationsService.getAllItemsForPublication(
            this.publication._id,
            this.step._id)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: (items) => this.publicationItems = items,
                error: (error) => Toaster.handleApiError(error)
            });
    }

    /**
     * Responds to delete button clicks
     * @param {UploadGroupModel} uploadGroup
     */
    public onDeleteButtonClicked(uploadGroup: UploadGroupModel): void {
        this.uploadGroups = this.uploadGroups.filter((foundGroup) => foundGroup !== uploadGroup);
        this.uploadGroups$.next(this.uploadGroups);
    }

    /**
     * Clear list of uploaded files
     */
    public clearUploadGroups(): void {
        this.uploadGroups = [];
        this.uploadGroups$.next(this.uploadGroups);
    }

    /**
     * Returns the upload for the provided fileType
     * @param {UploadModel[]} uploads
     * @param {string} fileType
     * @returns {UploadModel}
     */
    public getUploadForFileType(uploads: UploadModel[], fileType: string): UploadModel {
        return uploads.find((upload) => upload.fileType === fileType);
    }

    public dragEnter(): void {
        this.isDragging = true;
    }

    public dragLeave(): void {
        this.isDragging = false;
    }

    public dragOver(event): void {
        event.preventDefault();
    }

    public drop(event): void {
        event.preventDefault();
        this.isDragging = false;
        let files: File[] = [];
        if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
            files = Array.from(event.dataTransfer.files);
            this.onFilesChanged(files);
        }
        event.stopPropagation();
    }

    protected requestClose(): void {
        if (this.uploadGroups.length > 0) {
            const dialogConfig = new NucDialogConfigModel('Uploading Items.', 'There are unsaved changes that will be lost.');
            const dialog = this.dialogService.openDialog(dialogConfig);
            dialogConfig.addAction('Cancel', BUTTON_TYPE.SECONDARY).subscribe(() => {
                dialog.close();
                this.closeSubscription.next(false);
            });
            dialogConfig.addAction('Ok', BUTTON_TYPE.PRIMARY).subscribe(() => {
                dialog.close();
                this.uploadGroups = [];
                this.closeSubscription.next(true);
            });
        } else {
            this.closeSubscription.next(true);
        }
    }

    protected refreshData(): void {
    }

    public onFilesSelected(event) {
        this.isDragging = false;
        let files: File[] = [];
        if (event.target.files && event.target.files.length > 0) {
            files = Array.from(event.target.files);
            this.onFilesChanged(files);
        }
    }
}
