import * as countriesProvider from 'i18n-iso-countries';
import * as countriesEN from 'i18n-iso-countries/langs/en.json';
import {CountryModel} from '../models/ui/country.model';

export class CountryUtil {
    private static countryArray: CountryModel[];
    private static countries: Record<string, string>;

    /**
     * Get the locale from the localstorage, save the country and language code and fill an array with country models from the countries provider
     */
    public static initialize(): void {
        countriesProvider.registerLocale(countriesEN);
        // Only support English country names
        this.countries = countriesProvider.getNames(countriesEN.locale);

        this.countryArray = [];

        for (const key of Object.keys(this.countries)) {
            const value = this.countries[key];
            this.countryArray.push(new CountryModel({title: value, code: key}));
        }
    }

    /**
     * Get countries array
     * @returns {Array<CountryModel>}
     */
    public static getCountryArray(): CountryModel[] {
        return this.countryArray;
    }
}
