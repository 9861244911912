import {Component, OnInit, signal} from '@angular/core';
import {UpdateUserModel, UserDetailModel} from '../../../models/api/user.model';
import {UserService} from '../../../api/services/users.service';
import {Toaster} from '../../../classes/toaster.class';
import {ChangePasswordFormComponent} from '../../../forms/change-password-form/change-password-form.component';
import {TeamAccountService} from '../../../api/services/team-account.service';
import {TeamModel} from '../../../models/api/team.model';
import {UserIsAllowedToPipe} from '../../../pipes/user-is-allowed-to.pipe';
import {TransferOwnershipFormComponent} from '../../../forms/transfer-ownership-form/transfer-ownership-form.component';
import {RLBaseComponent} from '../../../components/rl-base-component/rl-base.component';
import {FullModalConfig, FullModalService} from '@relayter/rubber-duck';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {forkJoin, of, startWith} from 'rxjs';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {distinctUntilChanged, map} from 'rxjs/operators';

interface IAccountForm {
    fullName: FormControl<string>;
    email: FormControl<string>;
}

@Component({
    selector: 'rl-account-component',
    templateUrl: 'account.component.html',
    styleUrls: ['account.component.scss']
})
export class AccountComponent extends RLBaseComponent implements OnInit {
    public user: UserDetailModel;
    public team: TeamModel; // to check if user is owner of the team
    public formGroup: FormGroup<IAccountForm> = new FormGroup({
        fullName: new FormControl('', Validators.required),
        email: new FormControl({value: null, disabled: true}) // cannot edit email
    });
    public formChanged = signal<boolean>(false);

    constructor(private userService: UserService,
                private userIsAllowedToPipe: UserIsAllowedToPipe,
                private fullModalService: FullModalService,
                private teamService: TeamAccountService) {
        super();
    }

    public ngOnInit(): void {
        this.getData();
    }

    public onChangePasswordButtonClicked(): void {
        const config = new FullModalConfig(
            'Change your password',
            'You have requested to change the password for your account.');
        config.confirmClose = true;

        this.fullModalService.open(ChangePasswordFormComponent, config);
    }

    public onTransferOwnershipButtonClicked(): void {
        const modalData = {owner: this.user};
        const config = new FullModalConfig(
            'Transfer ownership',
            'Fill in the information below to transfer ownership to another user. When transferred it can only be changed back by the new owner.',
            modalData);
        config.confirmClose = true;

        this.fullModalService.open(TransferOwnershipFormComponent, config)
            .afterClosed().subscribe((res) => res ? this.getData() : null);
    }

    /**
     * on Init get user and team details
     */
    private getData(): void {
        forkJoin([
            this.userService.getMe(),
            this.userIsAllowedToPipe.transform(this.permissions.GET_TEAM_DETAILS) ?
                this.teamService.getTeamDetails() : of(null)
        ])
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: ([user, team]) => {
                    this.user = user;
                    this.team = team;
                    this.formGroup.patchValue(this.user);
                    this.listenToFormValueChanges();
                },
                error: Toaster.handleApiError
            });
    }

    private listenToFormValueChanges(): void {
        this.formGroup.valueChanges.pipe(
            startWith(this.formGroup.value),
            map((formValue) => {
                return formValue.fullName !== this.user?.fullName;
            }),
            distinctUntilChanged(),
            takeUntilDestroyed(this.destroyRef)
        ).subscribe((formChanged) => {
            this.formChanged.set(formChanged);
        });
    }

    public updateAccount(): void {
        const updatedUser = new UpdateUserModel();
        updatedUser.fullName = this.formGroup.value.fullName;
        this.userService.patchUserDetails(this.user._id, updatedUser)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: (updatedUser) => {
                    Toaster.success('Updated user successfully');
                    this.user = updatedUser;
                    this.formGroup.patchValue({fullName: updatedUser.fullName});
                },
                error: Toaster.handleApiError
            });
    }
}
