<ng-container *ngIf="permissions.GET_PACKAGE_SETUPS | userIsAllowedTo; else noPermissionState">
    <multi-item-selection-component [tableId]="tableId"
                                    [columns]="columns"
                                    [loading]="!subscription?.closed"
                                    [selectedItems]="selectedSetups"
                                    (selectedItemsChange)="onSelectionChanged($event)"
                                    (onSortOptionChange)="onSortOptionChanged($event)"
                                    [items]="packageSetups"
                                    [disableNextPage]="disableNextPage"
                                    [noResultsTitle]="searching ? 'No item matches your search criteria' : 'There are no packages available to add'"
                                    noSelectedItemsTitle="To create a package, you need to select a package setup.
                                                      You can create multiple packages at the same time by selecting more setups."
                                    selectionTitle="package setups">
        <ng-container button-bar-left>
            <nuc-input prefix="nucicon_search" class="search"
                       (debounceValueChanged)="onSearchBarValueUpdated($event)"
                       [placeholder]="'Search'">
            </nuc-input>
        </ng-container>
    </multi-item-selection-component>
</ng-container>

<ng-template #noPermissionState>
    <nuc-empty-state-component imageUrl="assets/images/empty_states/packages-selection.svg"
                               title="You do not have the permission to view package setups."
                               [small]="true">
    </nuc-empty-state-component>
</ng-template>
