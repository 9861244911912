<ng-container *ngIf="user; else loadingIndicator">
    <div class="header-row">
        <h1>{{user.fullName}}</h1>
    </div>

    <div class="user-management-user-details">
        <nuc-form-field label="Full Name">
            <nuc-input [disabled]="true" [data]="user.fullName"></nuc-input>
        </nuc-form-field>

        <nuc-form-field label="Email Address">
            <nuc-input [disabled]="true" [data]="user.email"></nuc-input>
        </nuc-form-field>
    </div>

    <div class="user-management-user-roles__list">
        <nuc-table [items]="items"
                   [emptyStateTitle]="searchValue ? 'No item matches your search criteria.' : null"
                   [columns]="columns"
                   [loading]="!subscription?.closed"
                   [tableId]="tableId"
                   [prefixStorageKey]="storageKey"
                   (sortOptionChange)="onSortOptionChanged($event)"
                   [selectionMode]="ESelectionMode.MULTI"
                   [selection]="selection">
            <nuc-input button-bar-left
                       class="search"
                       [(data)]="searchValue"
                       prefix="nucicon_search"
                       (debounceValueChanged)="resetPageIndex()"
                       placeholder="Search roles">
            </nuc-input>
            <ng-container button-bar-right>
                <nuc-button-primary
                    *ngIf="!canDeactivate()"
                    [disabled]="!(permissions.PATCH_USER | userIsAllowedTo) || !subscription?.closed"
                    (click)="openUpdateRolesDialog()"
                    text="Save changes">
                </nuc-button-primary>
                <paginator [viewId]="tableId"
                           [disableNextPage]="pageSize * pageIndex >= total"
                           [loading]="!subscription?.closed">
                </paginator>
            </ng-container>
        </nuc-table>
    </div>
</ng-container>

<ng-template #loadingIndicator>
    <rl-loading-indicator></rl-loading-indicator>
</ng-template>
