<form [formGroup]="formGroup" *ngIf="formGroup; else loading">
    <nuc-form-field label="Publication name">
        <nuc-input formControlName="name" placeholder="Provide a name for the publication" required></nuc-input>
    </nuc-form-field>

    <nuc-form-field label="Tags">
        <nuc-suggestive-input
            formControlName="tags"
            (searchTermChange)="onTagChanged($event)"
            [options]="tagOptions"
            placeholder="Add tags">
        </nuc-suggestive-input>
    </nuc-form-field>

    <nuc-form-field *ngIf="variants?.length" label="Variants" icon="nucicon_variant" iconColorClass="variant-highlight">
        <nuc-dropdown-multiselect
            [placeholder]="publicationToEdit ? 'No variants' : 'Select variants'"
            formControlName="variants"
            [items]="variants">
        </nuc-dropdown-multiselect>
    </nuc-form-field>

    <ng-container>
        <nuc-form-field label="Workflow">
            <nuc-dropdown formControlName="workflow"
                          [items]="workflowConfigurations"
                          placeholder="Select a workflow for the publication"
                          required>
            </nuc-dropdown>
        </nuc-form-field>
    </ng-container>
    <nuc-form-field label="Deadline">
        <nuc-datepicker
            formControlName="deadline"
            placeholder="Pick an end date"
        ></nuc-datepicker>
    </nuc-form-field>

</form>
<ng-template #loading>
    <rl-loading-indicator></rl-loading-indicator>
</ng-template>
