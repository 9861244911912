import {StaticContentTemplateSizeModel} from './static-content-template-size.model';

export class PixiTemplate extends PIXI.Container {

    private static readonly MARGIN_COLOR = 0xFFCC3D;
    private static readonly PAGE_BACKGROUND_COLOR = 0xffffff;
    private static readonly TEMPLATE_BORDER_COLOR = 0x85AC9A;
    private scaleRatio: number;

    constructor(templateSize: StaticContentTemplateSizeModel, numberOfPages: number, scale: number, scaleRatio: number = 1) {
        super();
        this.scaleRatio = scaleRatio;
        this.drawPage(templateSize, numberOfPages, 0, scale);
        if (numberOfPages === 2) this.drawPage(templateSize, numberOfPages, 1, scale);
    }

    private drawPage(templateSize: StaticContentTemplateSizeModel, numberOfPages: number, pageIndex: number, scale: number): void {
        const page = new PIXI.Graphics();

        const width = templateSize.width * scale;
        const height = templateSize.height * scale;
        const marginTop = templateSize.margins.marginTop * scale;
        const marginBottom = templateSize.margins.marginBottom * scale;
        const marginStart = templateSize.margins.marginStart * scale;
        const marginEnd = templateSize.margins.marginEnd * scale;

        page.beginFill(PixiTemplate.PAGE_BACKGROUND_COLOR, 0.5);
        page.lineStyle(1 / this.scaleRatio, PixiTemplate.TEMPLATE_BORDER_COLOR, 1);
        page.drawRect(width * pageIndex, 0, width, height);
        page.endFill();

        page.beginFill(PixiTemplate.PAGE_BACKGROUND_COLOR, 0);
        page.lineStyle(1, PixiTemplate.MARGIN_COLOR, 1);

        if (numberOfPages === 2 && pageIndex % 2 === 0) { // even number page
            page.drawRect(width * pageIndex + marginEnd, marginTop, width - (marginStart + marginEnd), height - (marginTop + marginBottom));
        } else { // odd number page
            page.drawRect(width * pageIndex + marginStart, marginTop, width - (marginStart + marginEnd), height - (marginTop + marginBottom));
        }
        page.endFill();

        this.addChild(page);
    }

}
