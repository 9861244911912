import {Injectable, Pipe, PipeTransform} from '@angular/core';
import {ConfigService} from '../api/services/config-service';

@Injectable({providedIn: 'root'})
@Pipe({name: 'userIsAllowedTo'})
export class UserIsAllowedToPipe implements PipeTransform {

    private actions: string[];

    constructor(private configService: ConfigService) {
        // This pipe subscribes to the config$ on the ConfigService to receive updates (when a new user logs in or the user is logged out)
        // The config is refreshed by the guard on the RelayterContainerComponent route, so it should always be initialized before it's used
        // This is a bit hacky, but it works for now
        this.configService.config$.subscribe((config) => this.actions = config ? config.actions : null);
    }

    /**
     * Gets a permission or an array of permissions and returns true if user is allowed, false otherwise
     * Usage: *ngIf="( [permissions.GET_PRODUCTS] | userIsAllowedTo) && booleanCondition"
     * @param {string | string[]} action
     * @returns {boolean}
     */
    public transform(action: string | string[]): boolean {
        if (this.actions && action) {
            let hasPermission = true;
            if (action instanceof Array) {
                for (const a of action) {
                    if (this.actions.indexOf(a) < 0) {
                        hasPermission = false;
                        break;
                    }
                }
            } else {
                hasPermission = this.actions.indexOf(action) > -1;
            }
            return hasPermission;
        } else {
            return false;
        }
    }
}
