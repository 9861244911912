import {Injectable} from '@angular/core';
import {ARLogger} from '@relayter/core';
import {RLMockSegmentService} from './rl-mock-segment.service';
import {UserDetailModel} from '../../models/api/user.model';
import {AnalyticsBrowser} from '@segment/analytics-next';
import {environment} from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class RLSegmentService extends RLMockSegmentService {
    private analytics = new AnalyticsBrowser();
    /**
     * @constructor
     */
    constructor() {
        super();
        this.analytics.load({ writeKey: environment.SEGMENT_API_KEY }, {}) // destinations loaded, enqueued events are flushed
    }

    public setUser(user: UserDetailModel): void {
        this.user = user;
        this.analytics.ready().then(() => {
            this.identify();
        });
    }

    /**
     * Identify user in segmentService and show intercom widget
     */
    public identify(): void {
        if (this.user && !this.user.acceptPolicy) {
            return ARLogger.warn('the user did not accept tracking, identify events will not be logged');
        }

        if (this.analytics && this.user) {
            this.analytics.identify(this.user._id, {
                    name: this.user.fullName,
                    email: this.user.email,
                    company: {id: this.user.team.teamHash, name: this.user.team.name} // team.companyName is not used, so we use the name....
                }, {
                    Intercom: {
                        hideDefaultLauncher: false,
                        user_hash: this.user.userHash
                    }
                }).then(() => {
                    ARLogger.info('Segment Identify Done');
                });
        } else {
            const traits = {};
            if (this.user) {
                traits['name'] = this.user.fullName;
                traits['email'] = this.user.email;
            }
            const debugUserId = this.user && this.user._id ? this.user._id : '';
            ARLogger.debug(`Segment Debug IDENTIFY event -- parameters: userId: [${debugUserId}], traits: [${traits}]`);
        }
    }

    /**
     * Reset segmentService and hide intercom widget
     */
    public reset(): void {
        if (this.analytics) {
            this.analytics.reset();
            this.analytics.page(null, null, {}, {
                Intercom: {
                    hideDefaultLauncher: true
                }
            });
        }
    }

    /**
     * Log a page view event to segmentService
     * @param {string} category
     * @param {string} name
     * @param properties
     * @param options
     */
    public page(category?: string, name?: string, properties?: any, options?: any): void {
        if (this.user && !this.user.acceptTracking) {
            return ARLogger.warn('the user did not accept tracking, page events will not be logged');
        }
        if (this.analytics) {
            this.analytics.page(category, name, properties, options).then((res) => {
                    ARLogger.info('Page event logged' + res);
                },
                (err) => {
                    ARLogger.error(err);
                });
        }
    }

    /**
     * Log a track event to segmentService
     * @param {string} event
     * @param properties
     * @param options
     */
    public track(event: string, properties?: any, options?: any): void {
        if (this.user && !this.user.acceptTracking) {
            return ARLogger.warn('the user did not accept tracking, track events will not be logged');
        }
        if (this.analytics) {
            this.analytics.track(event, properties, options).then((res) => {
                    ARLogger.info('Track event logged' + res);
                },
                (err) => {
                    ARLogger.error(err);
                });
        } else {
            ARLogger.debug(`Segment Debug TRACK event (${event}) -- parameters: : properties: [${properties}], options: [${options}]`);
        }
    }
}
