import {AreaClickEvent, TemplateAreaPixi} from '../area/template-area.pixi';
import {TemplateAreaModel} from '../../../../../../../../models/api/template-area.model';
import {TemplateModel} from '../../../../../../../../models/api/template.model';
import {PixiTemplate} from '../../../../../../templates/static-content-template-form/static-content-template-editor/template.pixi';
import {MoveEvent, ResizeEvent} from '../item/item.pixi';
import {Subject} from 'rxjs';
import {IEditorOptions} from '../spread-editor.component';
import {StaticContentTemplateSizeModel}
    from '../../../../../../templates/static-content-template-form/static-content-template-editor/static-content-template-size.model';

export class PlaceEvent {
    constructor(public readonly templateArea: TemplateAreaModel,
                public readonly row: number,
                public readonly column: number,
                public readonly rowSpan: number,
                public readonly columnSpan: number) {}
}

export class TemplateStagePixi extends PIXI.Container {

    private onAreaClicked = new Subject<AreaClickEvent>();
    public onAreaClicked$ = this.onAreaClicked.asObservable();
    private readonly scaleRatio: number;

    constructor(private editorOptions: IEditorOptions, scaleRatio: number = 1) {
        super();
        this.scaleRatio = scaleRatio;
    }

    public setTemplate(template: TemplateModel): void {
        // TODO: Dont reuse this layout-editor model
        const templateSizeModel = new StaticContentTemplateSizeModel(template.staticContent.pageSize.width, template.staticContent.pageSize.height,
            template.staticContent.margins);

        // TODO: Scale
        const templatePixi = new PixiTemplate(templateSizeModel, template.staticContent.numberOfPages, 1, this.scaleRatio);
        this.addChild(templatePixi);

        template.staticContent.areas.forEach((templateArea) => {
            const pixiArea = new TemplateAreaPixi(templateArea, this.editorOptions, this.scaleRatio);
            pixiArea.onAreaClicked$.subscribe((event) => this.onAreaClicked.next(event));
            this.addChild(pixiArea);
        });
    }

    public resetHighlight(): void {
        this.children.forEach((child) => {
            if (child instanceof TemplateAreaPixi) {
                child.resetHighlight();
            }
        });
    }

    public highlight(event: MoveEvent | ResizeEvent | PlaceEvent): void {
        this.resetHighlight();
        if (event.templateArea) {
            const area = this.getPixiArea(event.templateArea);
            area.highlightChildren(event.row, event.column, event.rowSpan, event.columnSpan);
        }
    }

    public getPlaceEventForGlobalPosition(x: number, y: number): PlaceEvent {
        const area = this.getAreaForDragDropPoint(x, y);

        if (!area) {
            return;
        }

        const {row, column} = area.getRowAndColumnForGlobalPosition(x, y);

        return new PlaceEvent(area.templateArea, row, column, 1, 1);
    }

    private getPixiArea(templateArea: TemplateAreaModel): TemplateAreaPixi {
        return this.children.find((child) => child instanceof TemplateAreaPixi && child.templateArea === templateArea) as TemplateAreaPixi;
    }

    public getAreaForDragDropPoint(x: number, y: number): TemplateAreaPixi {
        return this.children.find((child) => child instanceof TemplateAreaPixi && child.getBounds().contains(x, y)) as TemplateAreaPixi;
    }
}
